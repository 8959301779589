import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Offcanvas, Spinner } from "react-bootstrap";
import apiProvider from "../../../../../utils/apiProvider";
import { useAuth } from "../../../auth";
import { format } from "date-fns";
import Pusher from "pusher-js";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import clsx from "clsx";

interface ConversationDrawerProps {
  conversationId: string | null;
  show: boolean;
  onHide: () => void;
  onClose: () => void;
}

interface MetaData {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

interface Messages {
  id: number;
  user_id: number;
  product: any;
  transaction: any;
  withdraw: any;
  photo: any;
  message: string;
  is_you: boolean;
  is_read: boolean;
  date: string;
}

const MessageBubble: React.FC<{ message: Messages }> = ({ message }) => {
  if (!message.message) return null;

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "HH:mm");
  };

  return (
    <div className="mb-3">
      <div
        key={message.id}
        className={clsx("d-flex mb-10", {
          "justify-content-start": !message.is_you,
          "justify-content-end": message.is_you,
        })}
      >
        <div className="d-flex flex-column align-items">
          <div
            className={clsx(
              "p-4 rounded",
              {
                "bg-light-info": !message.is_you,
                "bg-light-primary": message.is_you,
              },
              "text-dark fw-bold mw-lg-400px",
              {
                "text-start": !message.is_you,
                "text-end": message.is_you,
              }
            )}
            data-kt-element="message-text"
          >
            {message.message && <div className="mb-2">{message.message}</div>}
            {message.photo && (
              <div className="attachment">
                <a
                  href={message.photo}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  <img
                    src={message.photo}
                    alt="attachment"
                    className="img-fluid rounded"
                    style={{ maxHeight: "200px" }}
                    loading="lazy"
                  />
                </a>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center gap-2 mt-1 px-1">
            <small className="text-muted">{formatDate(message.date)}</small>
            {message.is_you && (
              <small
                className={message.is_read ? "text-success" : "text-muted"}
              >
                {message.is_read ? (
                  <BsCheckAll size={16} />
                ) : (
                  <BsCheck size={16} />
                )}
              </small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FilePreview: React.FC<{
  file: File;
  onRemove: () => void;
}> = ({ file, onRemove }) => (
  <div className="mb-2">
    <Card className="bg-light">
      <Card.Body className="p-2 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center overflow-hidden">
          <i className="bi bi-paperclip me-2"></i>
          <span className="text-truncate">{file.name}</span>
        </div>
        <Button
          variant="link"
          className="text-danger p-0 ms-2"
          onClick={onRemove}
        >
          <i className="bi bi-x-lg"></i>
        </Button>
      </Card.Body>
    </Card>
  </div>
);

const ConversationDrawer: React.FC<ConversationDrawerProps> = ({
  conversationId,
  show,
  onHide,
  onClose,
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [fileInput, setFileInput] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [metaData, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 99,
    total: 0,
    total_pages: 0,
  });
  const token = currentUser?.token;
  const [messages, setMessages] = useState<Messages[]>([]);
  const [chatUserId, setChatUserId] = useState<number | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!conversationId) return;

    const pusher = new Pusher("b1850c2dae770ae81718", {
      cluster: "ap1",
    });

    const channel = pusher.subscribe(`conversation.${conversationId}`);

    channel.bind(`conversation.${conversationId}`, (data: Messages) => {
      const modifiedData = {
        ...data,
        is_you: data.user_id === chatUserId,
      };

      setMessages((prevMessages) => [...prevMessages, modifiedData]);
      scrollToBottom();
    });

    pusher.connection.bind("state_change", (states: { current: string }) => {
      console.log("Pusher connection state:", states.current);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [conversationId, chatUserId]);

  useEffect(() => {
    if (show && conversationId) {
      getDetailChat(conversationId);
    }
  }, [show, conversationId]);

  const getDetailChat = async (conversationId: string) => {
    setLoading(true);
    const response = await apiProvider.GetDetailChat(
      token,
      conversationId,
      metaData.page,
      metaData.per_page
    );

    if (response.status === 200 && response?.data.data.length > 0) {
      setMessages((prevMessages) => {
        const newMessages = response.data.data.reverse();
        const uniqueMessages: Messages[] = newMessages.filter(
          (newMsg: Messages) =>
            !prevMessages.some((prevMsg: Messages) => prevMsg.id === newMsg.id)
        );
        const yourMessage = response.data.data.find(
          (msg: Messages) => msg.is_you
        );
        if (yourMessage) {
          setChatUserId(yourMessage.user_id);
        }
        return [...uniqueMessages, ...prevMessages];
      });
      setMetaData({
        page: response.data.meta.current_page,
        per_page: response.data.meta.total,
        total: response.data.meta.total,
        total_pages: response.data.meta.total_pages,
      });
      setTimeout(scrollToBottom, 100);
    } else {
      setMessages([]);
      setMetaData({
        page: 0,
        per_page: 0,
        total: 0,
        total_pages: 0,
      });
    }
    setLoading(false);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        alert("Ukuran file tidak boleh melebihi 2MB");
        return;
      }
      setFileInput(file);
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!message.trim() && !fileInput) || !conversationId) return;

    const formData = new FormData();
    formData.append("message", message);
    if (fileInput) formData.append("photo", fileInput);

    try {
      const response = await apiProvider.SendChat(
        formData,
        token,
        conversationId
      );
      if (response.status === 200) {
        setMessage("");
        setFileInput(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleClose = () => {
    setMessages([]);
    setMessage("");
    setFileInput(null);
    setChatUserId(null);
    setMetaData({
      page: 1,
      per_page: 99,
      total: 0,
      total_pages: 0,
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onClose();
    onHide();
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={{ maxWidth: "420px" }}
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Pesan</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="p-0 d-flex flex-column">
        <div className="flex-grow-1 overflow-auto px-3">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : messages.length === 0 ? (
            <div className="text-center text-muted p-4">
              <i className="bi bi-chat-dots fs-1 mb-2 d-block"></i>
              <p className="mb-0">Belum ada pesan. Mulai percakapan!</p>
            </div>
          ) : (
            <div className="py-3">
              {messages.map((message) => (
                <MessageBubble key={message.id} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
        </div>

        <div className="border-top p-3">
          {fileInput && (
            <FilePreview
              file={fileInput}
              onRemove={() => {
                setFileInput(null);
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
              }}
            />
          )}

          <Form onSubmit={handleSendMessage}>
            <div className="d-flex gap-2">
              <Form.Control
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                className="d-none"
                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
              />
              <Button
                variant="outline-secondary"
                onClick={() => fileInputRef.current?.click()}
              >
                <i className="bi bi-paperclip"></i>
              </Button>
              <Form.Control
                type="text"
                placeholder="Ketik pesan Anda..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                type="submit"
                variant="primary"
                disabled={!message.trim() && !fileInput}
              >
                <i className="bi bi-send-fill"></i>
              </Button>
            </div>
          </Form>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ConversationDrawer;
