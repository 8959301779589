import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { format } from "date-fns";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Skeleton } from "antd";
import { KTSVG } from "../../../../_metronic/helpers";

const SendNotificationValidationSchema = Yup.object().shape({
  user: Yup.string().oneOf(["buyer", "seller"], "Pilih tipe pengguna"),
  title: Yup.string().required("Judul wajib diisi"),
  body: Yup.string().required("Isi pesan wajib diisi"),
});

interface NotificationData {
  id: number;
  name: string;
}

interface HistoryNotification {
  id: number;
  title: string;
  body: string;
  date: string;
}

const NotificationComponent: React.FC = () => {
  // State Management
  const [loading, setLoading] = useState({
    submit: false,
    history: false,
  });
  const [alert, setAlert] = useState({
    show: false,
    type: "" as "success" | "error",
    message: "",
  });
  const [notificationHistory, setNotificationHistory] = useState<
    HistoryNotification[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<string>("");

  const { currentUser } = useAuth();
  const token = currentUser?.token;

  // Form Management
  const formik = useFormik({
    initialValues: {
      user: "",
      title: "",
      body: "",
    },
    validationSchema: SendNotificationValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading((prev) => ({ ...prev, submit: true }));
      try {
        const formData = new FormData();
        formData.append("type", selectedUser);
        formData.append("title", values.title);
        formData.append("body", values.body);

        const res = await API.SendNotification(formData, token);
        if (res.status === 200) {
          setAlert({
            show: true,
            type: "success",
            message: res.data.message,
          });
          resetForm();
          getHistoryNotification(); // Refresh history after sending
        }
      } catch (error) {
        setAlert({
          show: true,
          type: "error",
          message: "Gagal mengirim notifikasi",
        });
      } finally {
        setLoading((prev) => ({ ...prev, submit: false }));
      }
    },
  });

  // Alert auto-hide
  useEffect(() => {
    if (alert.show) {
      const timer = setTimeout(() => {
        setAlert((prev) => ({ ...prev, show: false }));
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [alert.show]);

  // Get notification history
  const getHistoryNotification = async () => {
    setLoading((prev) => ({ ...prev, history: true }));
    try {
      const res = await API.GetNotificationHistory(token);
      if (res.status === 200) {
        setNotificationHistory(res.data.data);
      }
    } catch (error) {
      setAlert({
        show: true,
        type: "error",
        message: "Gagal memuat riwayat notifikasi",
      });
    } finally {
      setLoading((prev) => ({ ...prev, history: false }));
    }
  };

  useEffect(() => {
    getHistoryNotification();
  }, []);

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "dd MMMM yyyy HH:mm");
  };

  return (
    <>
      {/* Notification Form Card */}
      <div className="card mb-5 mb-xl-10">
        {alert.show && (
          <AlertMessage type={alert.type} message={alert.message} />
        )}

        <div className="card-header border-0">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Pemberitahuan</h3>
          </div>
        </div>

        <div className="card-body border-top p-9">
          <form onSubmit={formik.handleSubmit} className="form" noValidate>
            {/* Target Selection */}
            <div className="mb-6">
              <label className="form-label fs-6 fw-bolder mb-3">
                Target Pemberitahuan
              </label>
              {loading.submit ? (
                <Skeleton.Input
                  style={{ width: "100%", height: "45px" }}
                  active
                  block
                />
              ) : (
                <select
                  className="form-select form-select-solid"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  <option value="" disabled>
                    Pilih pengguna
                  </option>
                  <option value="buyer">Buyer</option>
                  <option value="seller">Seller</option>
                </select>
              )}
              {formik.touched.user && formik.errors.user && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.user}</div>
                </div>
              )}
            </div>

            {/* Title Input */}
            <div className="mb-6">
              <label className="form-label fs-6 fw-bolder mb-3">
                Judul Pemberitahuan
              </label>
              {loading.submit ? (
                <Skeleton.Input
                  style={{ width: "100%", height: "45px" }}
                  active
                  block
                />
              ) : (
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Masukkan judul pemberitahuan"
                  {...formik.getFieldProps("title")}
                />
              )}
              {formik.touched.title && formik.errors.title && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              )}
            </div>

            {/* Body Input */}
            <div className="mb-6">
              <label className="form-label fs-6 fw-bolder mb-3">
                Teks Pemberitahuan
              </label>
              {loading.submit ? (
                <Skeleton.Input
                  style={{ width: "100%", height: "100px" }}
                  active
                  block
                />
              ) : (
                <textarea
                  className="form-control form-control-lg form-control-solid"
                  rows={4}
                  placeholder="Masukkan teks pemberitahuan"
                  {...formik.getFieldProps("body")}
                />
              )}
              {formik.touched.body && formik.errors.body && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.body}</div>
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-primary px-6"
                disabled={loading.submit}
              >
                {loading.submit ? (
                  <span className="indicator-progress">
                    Mengirim...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                ) : (
                  "Kirim Pemberitahuan"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Notification History Card */}
      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Riwayat Pemberitahuan</h3>
          </div>
        </div>

        <div className="card-body border-top p-9">
          {loading.history ? (
            <div className="py-5">
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          ) : notificationHistory.length === 0 ? (
            <div className="text-center py-10">
              <p className="text-gray-500">Belum ada riwayat pemberitahuan</p>
            </div>
          ) : (
            <div className="timeline">
              {notificationHistory.map((notif) => (
                <div key={notif.id} className="timeline-item">
                  <div className="timeline-line w-40px"></div>

                  <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                    <div className="symbol-label bg-light">
                      <KTSVG
                        path="/media/icons/duotune/general/gen007.svg"
                        className="svg-icon-1"
                      />
                    </div>
                  </div>

                  <div className="timeline-content mb-10 mt-2">
                    <div className="pe-3 mb-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="fs-5 fw-bold">{notif.title}</div>
                        <div className="text-muted fs-7">
                          {formatDate(notif.date)}
                        </div>
                      </div>
                    </div>

                    <div className="overflow-auto pb-5">
                      <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                        <div className="fs-7 text-dark fw-semibold">
                          {notif.body}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { NotificationComponent };
