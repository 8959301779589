import React from "react";
import { RegionType } from "./type";

interface RegionSelectProps {
  label: string;
  name: string;
  value: number;
  options: RegionType[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  loading: boolean;
  disabled?: boolean;
}

export const RegionSelect: React.FC<RegionSelectProps> = React.memo(
  ({ label, name, value, options, onChange, loading, disabled }) => (
    <div className="row mb-6">
      <label className="col-lg-4 col-form-label fw-bold fs-6">
        <span className="required">{label}</span>
      </label>
      <div className="col-lg-8 fv-row">
        <select
          name={name}
          value={loading ? "" : value}
          onChange={onChange}
          disabled={loading || disabled}
          className="form-select form-select-solid form-select-lg fw-bold"
        >
          <option value="">{loading ? "Loading..." : `Pilih ${label}`}</option>
          {!loading &&
            options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
);
