import React, { useEffect, useState, useRef } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import API from "../../../../../utils/apiProvider";
import { useAuth } from "../../../auth";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Empty, Pagination, Skeleton, Spin } from "antd";
import { AlertMessage } from "../../../global/AlertMessage";

interface Material {
  category: string;
  price: number | null;
}

interface Prices {
  "non-material": number;
  material: Material[];
}

interface Data {
  id: number;
  label: string;
  prices: Prices;
  code: string;
}

type MetaData = {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
};

const DetailPriceExpedition: React.FC = () => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState(true);
  const [detailPriceData, setDetailPriceData] = useState<Data | null>(null);
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [zeroData, setZeroData] = useState("");

  const location = useLocation();
  const {
    id1 = 0,
    id = 0,
    label = "",
    code = "",
  } = (location.state as {
    id1?: number;
    id?: number;
    label?: string;
    name?: string;
    code?: string;
  }) || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getDetailPriceData();
  }, [location.state, token, searchQuery]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  useEffect(() => {
    if (detailPriceData) {
      const filteredMaterials = detailPriceData.prices.material.filter(
        (material) =>
          material.category.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setMetaData({
        ...meta,
        total: filteredMaterials.length,
        total_pages: Math.ceil(filteredMaterials.length / pageSize),
      });
    } else {
      setMetaData({
        ...meta,
        total: 0,
        total_pages: 0,
      });
    }
  }, [detailPriceData, searchQuery, pageSize]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);

    const filteredMaterials = detailPriceData?.prices.material?.filter(
      (material) =>
        material.category.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (filteredMaterials && e.target.value.trim() !== "") {
      const newMeta = {
        ...meta,
        total: filteredMaterials.length,
        total_pages: Math.ceil(filteredMaterials.length / pageSize),
      };

      setMetaData(newMeta);
    } else {
      const newMeta = {
        ...meta,
        total: detailPriceData?.prices.material.length || 0,
        total_pages: Math.ceil(
          (detailPriceData?.prices.material.length || 0) / pageSize
        ),
      };

      setMetaData(newMeta);
    }
  };

  const getDetailPriceData = async () => {
    setLoading(true);

    const resTransactionUser = await API.GetPriceDetailExp(token, id1, id);

    if (resTransactionUser.status === 200) {
      setDetailPriceData(resTransactionUser.data.data);
      setMetaData({
        ...meta,
        total: resTransactionUser.data.data.prices.material.length,
        total_pages: Math.ceil(
          resTransactionUser.data.data.prices.material.length / meta.per_page
        ),
      });
    } else {
      setDetailPriceData(null);
      console.error("Error fetching home data:", resTransactionUser);
    }
    setLoading(false);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      await handleImportPriceMaterial(file);
    }
  };

  const handleImportPriceMaterial = async (file: File) => {
    const formDataAPI = new FormData();
    formDataAPI.append("file", file);

    const resImportPriceMaterial = await API.ImportPriceMaterial(
      formDataAPI,
      id1,
      id,
      token
    );

    if (resImportPriceMaterial.status === 200) {
      await getDetailPriceData();
      setShowAlert(true);
      setSuccessMessage(resImportPriceMaterial.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resImportPriceMaterial.data.message);
    }
  };

  const handleExportPriceMaterial = async () => {
    setLoading(true);
    try {
      const response = await API.GetPriceExportExp(token, id1, id);

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Template_Harga_${code}_${label}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setShowAlert(true);
        setSuccessMessage("File telah berhasil diunduh.");
      } else {
        setShowAlertError(true);
        setErrorMessage("Terjadi kesalahan saat mengekspor harga material.");
      }
    } catch (error) {
      setShowAlertError(true);
      setErrorMessage("Terjadi kesalahan saat mengekspor harga material.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleExportPriceMaterial = async () => {
  //   setLoading(true);
  //   const response = await API.GetPriceExportExp(token, id1, id);

  //   if (response.data instanceof ArrayBuffer) {
  //     const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const url = window.URL.createObjectURL(new Blob([response]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `Template_Harga_${code}_${label}.xlsx`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     setShowAlert(true);
  //     setSuccessMessage("File telah berhasil diunduh.");
  //   } else {
  //     setShowAlertError(true);
  //     setErrorMessage(
  //       response?.data?.message ||
  //         "Terjadi kesalahan saat mengekspor harga material."
  //     );
  //   }
  //   setLoading(false);
  // };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };
  const filteredMaterials = detailPriceData?.prices.material
    .filter((material) =>
      material.category.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const formik1 = useFormik({
    initialValues: {
      biaya:
        detailPriceData?.prices["non-material"] !== undefined &&
        detailPriceData?.prices["non-material"] !== null
          ? detailPriceData.prices["non-material"].toString()
          : "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      const formDataAPI = new FormData();
      formDataAPI.append("price", values.biaya);

      const resPriceNonMaterial = await API.ImportPriceNonMaterial(
        formDataAPI,
        id1,
        id,
        token
      );

      if (resPriceNonMaterial.status === 200) {
        await getDetailPriceData();
        setShowAlert(true);
        setSuccessMessage(resPriceNonMaterial.data.message);
      } else {
        setShowAlertError(true);
        setErrorMessage(resPriceNonMaterial.data.message);
      }
      setLoading(false);
    },
  });

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_signin_method"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {loading ? (
                <Skeleton.Input active />
              ) : (
                `Harga Non-Material (Kode: ${code} | Label: ${label})`
              )}
            </h3>
          </div>
        </div>
        <div id="kt_account_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            {detailPriceData ? (
              <form
                onSubmit={formik1.handleSubmit}
                id="kt_signin_change_email"
                className="form"
                noValidate
              >
                <div className="col mb-6">
                  <label className="d-flex col-lg-4 col-form-label fw-bold fs-6">
                    Harga :
                  </label>
                  <input
                    type="text"
                    className="d-flex form-control form-control-lg form-control-solid"
                    id="biaya"
                    name="biaya"
                    placeholder="Masukkan harga non-material"
                    value={formik1.values.biaya}
                    onChange={(e) => {
                      formik1.handleChange(e);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    id="kt_signin_submit"
                    type="submit"
                    className="btn btn-primary me-2 px-6"
                  >
                    {!loading && "Simpan Perubahan"}
                    {loading && (
                      <span className="indicator-progress">
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <Skeleton.Input active />
            )}
          </div>
        </div>
      </div>

      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_signin_method1"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {loading ? (
                <Skeleton.Input active />
              ) : (
                `Harga Material (Kode: ${code} | Label: ${label})`
              )}
            </h3>
          </div>
        </div>
        <div className="separator separator"></div>
        <div id="kt_account_signin_method1" className="collapse show mt-2">
          <div className="card-header border-0">
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-sm btn-light-primary me-4"
                onClick={() => window.history.back()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr002.svg"
                  className="svg-icon-2"
                />
              </button>
              <div
                className="d-flex justify-content-end me-4"
                data-kt-user-table-toolbar="base"
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  className="form-control me-3 d-none"
                  onChange={handleFileChange}
                  accept=".xlsx"
                />
                <button
                  type="button"
                  className="btn btn-sm btn-light-primary"
                  onClick={openFilePicker}
                  disabled={
                    !detailPriceData?.prices["non-material"] || !detailPriceData
                  }
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil022.svg"
                    className="svg-icon-2"
                  />
                  Impor (Daftar Harga)
                </button>
              </div>
              <div
                className="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-sm btn-light-primary"
                  onClick={handleExportPriceMaterial}
                  disabled={
                    !detailPriceData?.prices["non-material"] || !detailPriceData
                  }
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil021.svg"
                    className="svg-icon-2"
                  />
                  Ekspor (Daftar Harga)
                </button>
              </div>
            </div>

            <div className="card-toolbar">
              <div className="d-flex align-items-center position-relative">
                <KTSVG
                  path="/media/icons/duotune/general/gen021.svg"
                  className="svg-icon-1 position-absolute ms-6"
                />
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-250px ps-14"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>

          <div className="card-body py-3">
            <div className="table-responsive">
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                <thead>
                  <tr className="fw-bold text-muted bg-light">
                    <th className="min-w-40px rounded-start text-center">No</th>
                    <th className="min-w-125px text-center">Kategori</th>
                    <th className="min-w-40px rounded-end text-center">
                      Harga
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={8}
                        className="text-center text-muted fw-bold fs-6"
                      >
                        <Spin size="default" />
                      </td>
                    </tr>
                  ) : filteredMaterials && filteredMaterials.length > 0 ? (
                    filteredMaterials.map((material, index) => (
                      <tr key={index + 1}>
                        <td className="text-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center flex-column">
                              <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                                {(currentPage - 1) * pageSize + index + 1}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-dark fw-bold mb-1 fs-6">
                                {material.category}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="text-dark fw-bold mb-1 fs-6">
                            {material.price !== null
                              ? material.price.toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })
                              : "Rp 0"}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={8}
                        className="text-center text-muted fw-bold fs-6"
                      >
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 100 }}
                          description={
                            <span className="text-muted fw-bold">
                              Data tidak ditemukan
                            </span>
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
              <div>
                <select
                  value={pageSize}
                  onChange={(e) =>
                    handlePageChange(1, parseInt(e.target.value))
                  }
                  className="form-select form-select-sm form-select-solid d-inline-block"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="9999">All</option>
                </select>
              </div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={
                  searchQuery.trim() !== ""
                    ? meta.total
                    : detailPriceData?.prices.material.length || 0
                }
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { DetailPriceExpedition };
