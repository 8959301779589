// components/ListChat.tsx
import React, { FC } from "react";
import { ChatInner } from "./ChatInner";
import { useChat } from "../hooks/useChat";
import { ChatUserList } from "./ChatUSerList";

interface ListChatProps {
  role: string;
}

export const ListChat: FC<ListChatProps> = ({ role }) => {
  const {
    loading,
    users,
    selectedUser,
    selectedUserId,
    error,
    searchTerm,
    setSearchTerm,
    setSelectedUserId,
  } = useChat(role);

  return (
    <div className="d-flex flex-column flex-lg-row">
      <div className="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-400px mb-10 mb-lg-0">
        <ChatUserList
          users={users}
          selectedUserId={selectedUserId}
          loading={loading}
          error={error}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          onUserSelect={setSelectedUserId}
        />
      </div>

      <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
        {!selectedUser ? (
          <div className="card">
            <div className="card-body p-0">
              <div className="card-px text-center py-20 my-10">
                <h2 className="fs-2x fw-bold mb-10">Belum Ada Obrolan Aktif</h2>
                <p className="text-gray-500 fs-4 fw-semibold mb-10">
                  Anda belum memulai obrolan dengan siapapun.
                  <br />
                  Mulailah dengan memilih kontak disamping untuk memulai
                  obrolan.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <ChatInner
              name={selectedUser.name}
              photo={selectedUser.photo}
              messageId={selectedUserId}
              key={selectedUserId}
            />
          </div>
        )}
      </div>
    </div>
  );
};
