import React from "react";
import { Skeleton } from "antd";

interface PhotoUploadProps {
  previewPhoto: string | null;
  photo: string;
  loading: boolean;
  onPhotoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PhotoUpload: React.FC<PhotoUploadProps> = React.memo(
  ({ previewPhoto, photo, loading, onPhotoChange }) => (
    <div className="row mb-6">
      <label className="col-lg-4 col-form-label fw-bolder fs-6">
        Foto Profil
      </label>
      <div className="col-lg-8">
        <div className="image-input image-input-outline">
          {loading ? (
            <Skeleton.Avatar size={125} shape="circle" active />
          ) : (
            <div
              className="image-input-wrapper w-125px h-125px"
              style={{
                borderRadius: "100%",
                backgroundImage: `url(${previewPhoto || photo || ""})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
          <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow">
            <i className="bi bi-pencil-fill fs-7" />
            <input
              type="file"
              name="photo"
              accept=".png, .jpg, .jpeg"
              onChange={onPhotoChange}
              style={{ display: "none" }}
            />
          </label>
        </div>
        <div className="form-text">
          Tipe file yang diizinkan: png, jpg, jpeg. Ukuran maksimum file: 2 MB.
        </div>
      </div>
    </div>
  )
);
