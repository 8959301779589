import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../auth";
import { useLocation } from "react-router-dom";
import { AlertMessage } from "../../global/AlertMessage";
import { PhotoUpload } from "./components/PhotoUpload";
import { RegionSelect } from "./components/RegionSelect";
import { FormDataType, ShopData } from "./components/type";
import { useRegions } from "./hooks/useRegion";
import apiProvider from "../../../../utils/apiProvider";

export const UpdateShop: React.FC = () => {
  const location = useLocation();
  const state = location.state as ShopData;
  const { currentUser } = useAuth();
  const {
    regions,
    loading: regionsLoading,
    fetchRegion,
  } = useRegions(currentUser?.token);

  const [loading, setLoading] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<{
    show: boolean;
    type: "success" | "error";
    message: string;
  }>({ show: false, type: "success", message: "" });

  const [formData, setFormData] = useState<FormDataType>({
    name: state.name,
    phone: state.phone,
    province: state.province.id,
    city: state.city.id,
    district: state.district.id,
    subdistrict: state.subdistrict.id,
    zip_code: state.zip_code,
    address_detail: state.address_detail,
    description: state.description,
    is_both: state.is_both,
    is_free_shipping: state.is_free_shipping,
    is_open: state.is_open,
    is_verified: state.is_verified,
  });

  const [coordinates, setCoordinates] = useState({
    lat: state.lat,
    lng: state.lng,
  });

  const [regionLoading, setRegionLoading] = useState({
    province: false,
    city: false,
    district: false,
    subdistrict: false,
    zipcode: false,
  });

  useEffect(() => {
    const initializeRegions = async () => {
      setRegionLoading((prev) => ({ ...prev, province: true }));
      await fetchRegion("province");
      setRegionLoading((prev) => ({ ...prev, province: false }));

      if (state.province.id) {
        setRegionLoading((prev) => ({ ...prev, city: true }));
        await handleProvinceChange({
          target: { value: state.province.id.toString() },
        } as React.ChangeEvent<HTMLSelectElement>);

        if (state.city.id) {
          setRegionLoading((prev) => ({ ...prev, district: true }));
          await handleCityChange({
            target: { value: state.city.id.toString() },
          } as React.ChangeEvent<HTMLSelectElement>);

          if (state.district.id) {
            setRegionLoading((prev) => ({ ...prev, subdistrict: true }));
            await handleDistrictChange({
              target: { value: state.district.id.toString() },
            } as React.ChangeEvent<HTMLSelectElement>);

            if (state.subdistrict.id) {
              setRegionLoading((prev) => ({ ...prev, zipcode: true }));
              await handleSubdistrictChange({
                target: { value: state.subdistrict.id.toString() },
              } as React.ChangeEvent<HTMLSelectElement>);
            }
          }
        }
      }
    };

    initializeRegions();
  }, []);

  const showAlert = useCallback(
    (type: "success" | "error", message: string) => {
      setAlert({ show: true, type, message });
      const timer = setTimeout(
        () => setAlert((prev) => ({ ...prev, show: false })),
        4000
      );
      return () => clearTimeout(timer);
    },
    []
  );
  const handleProvinceChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const provinceId = parseInt(e.target.value);
    setFormData((prev) => ({
      ...prev,
      province: provinceId,
      city: 0,
      district: 0,
      subdistrict: 0,
    }));

    setRegionLoading((prev) => ({ ...prev, city: true }));
    await fetchRegion("city", provinceId);
    setRegionLoading((prev) => ({ ...prev, city: false }));
  };

  const handleCityChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cityId = parseInt(e.target.value);
    setFormData((prev) => ({
      ...prev,
      city: cityId,
      district: 0,
      subdistrict: 0,
    }));

    setRegionLoading((prev) => ({ ...prev, district: true }));
    await fetchRegion("district", formData.province, cityId);
    setRegionLoading((prev) => ({ ...prev, district: false }));
  };

  const handleDistrictChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const districtId = parseInt(e.target.value);
    setFormData((prev) => ({
      ...prev,
      district: districtId,
      subdistrict: 0,
    }));

    setRegionLoading((prev) => ({ ...prev, subdistrict: true }));
    await fetchRegion(
      "sub-district",
      formData.province,
      formData.city,
      districtId
    );
    setRegionLoading((prev) => ({ ...prev, subdistrict: false }));
  };

  const handleSubdistrictChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const subdistrictId = parseInt(e.target.value);
    setFormData((prev) => ({
      ...prev,
      subdistrict: subdistrictId,
    }));

    setRegionLoading((prev) => ({ ...prev, zipcode: true }));
    const zipCodes = await fetchRegion(
      "zip-code",
      formData.province,
      formData.city,
      formData.district,
      subdistrictId
    );
    setRegionLoading((prev) => ({ ...prev, zipcode: false }));

    if (zipCodes && zipCodes.length > 0) {
      setFormData((prev) => ({
        ...prev,
        zip_code: zipCodes[0].name,
      }));
      setCoordinates({
        lat: zipCodes[0].lat || "",
        lng: zipCodes[0].lng || "",
      });
    }
  };

  const handleChangeProductType = useCallback((isBothSelected: boolean) => {
    setFormData((prev) => ({
      ...prev,
      is_both: isBothSelected,
    }));
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    const formDataAPI = new FormData();
    if (photoFile) formDataAPI.append("photo", photoFile);
    formDataAPI.append("name", formData.name);
    formDataAPI.append("phone", formData.phone);
    formDataAPI.append("province_id", formData.province.toString());
    formDataAPI.append("city_id", formData.city.toString());
    formDataAPI.append("district_id", formData.district.toString());
    formDataAPI.append("subdistrict_id", formData.subdistrict.toString());
    formDataAPI.append("zip_code", formData.zip_code.toString());
    formDataAPI.append("lat", coordinates.lat);
    formDataAPI.append("lng", coordinates.lng);
    formDataAPI.append("address_detail", formData.address_detail);
    if (formData.description) {
      formDataAPI.append("description", formData.description);
    }

    formDataAPI.append("is_both", formData.is_both.toString());
    formDataAPI.append(
      "is_free_shipping",
      formData.is_free_shipping.toString()
    );
    formDataAPI.append("is_open", formData.is_open.toString());
    formDataAPI.append("is_verified", formData.is_verified.toString());

    const resUpdateShop = await apiProvider.UpdateShop(
      formDataAPI,
      currentUser?.token,
      state.id
    );

    if (resUpdateShop.status === 200) {
      showAlert("success", resUpdateShop.data.message);
    } else {
      showAlert("error", resUpdateShop.data.message);
    }

    setLoading(false);
  };

  const handlePhotoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      if (file.size > 2 * 1024 * 1024) {
        showAlert("error", "File size must be less than 2MB");
        return;
      }

      setPhotoFile(file);
      setPreviewPhoto(URL.createObjectURL(file));
    },
    [showAlert]
  );

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name, value, type } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]:
          type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
      }));
    },
    []
  );

  return (
    <div className="card mb-5 mb-xl-10">
      {alert.show && <AlertMessage type={alert.type} message={alert.message} />}

      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Edit Toko</h3>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="form">
        <div className="card-body border-top p-9">
          <PhotoUpload
            previewPhoto={previewPhoto}
            photo={state.photo}
            loading={loading}
            onPhotoChange={handlePhotoChange}
          />

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Nama Toko
            </label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="name"
                className="form-control form-control-lg form-control-solid"
                placeholder="Nama Toko"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Nomor Telepon
            </label>
            <div className="col-lg-8 fv-row">
              <input
                name="phone"
                type="number"
                className="form-control form-control-lg form-control-solid"
                placeholder="Nomor Telepon"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <RegionSelect
            label="Provinsi"
            name="province"
            value={formData.province}
            options={regions.province}
            onChange={handleProvinceChange}
            loading={regionLoading.province}
          />

          <RegionSelect
            label="Kabupaten/Kota"
            name="city"
            value={formData.city}
            options={regions.city}
            onChange={handleCityChange}
            loading={regionLoading.city}
            disabled={!formData.province}
          />

          <RegionSelect
            label="Kecamatan"
            name="district"
            value={formData.district}
            options={regions.district}
            onChange={handleDistrictChange}
            loading={regionLoading.district}
            disabled={!formData.city}
          />

          <RegionSelect
            label="Kelurahan"
            name="subdistrict"
            value={formData.subdistrict}
            options={regions.subdistrict}
            onChange={handleSubdistrictChange}
            loading={regionLoading.subdistrict}
            disabled={!formData.district}
          />

          {/* Kode Pos */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-bold fs-6">
              <span className="required">Kode Pos</span>
            </label>
            <div className="col-lg-8 fv-row">
              <input
                className="form-control form-control-lg form-control-solid"
                disabled
                value={
                  regionLoading.zipcode ? "Loading..." : formData.zip_code || ""
                }
              />
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Alamat Lengkap
            </label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="address_detail"
                className="form-control form-control-lg form-control-solid"
                placeholder="Alamat Lengkap"
                value={formData.address_detail}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Deskripsi Toko
            </label>
            <div className="col-lg-8 fv-row">
              <textarea
                name="description"
                className="form-control form-control-lg form-control-solid"
                placeholder="Deskripsi Toko"
                value={formData.description}
                onChange={handleInputChange}
                style={{
                  minHeight: "100px",
                  height: "auto",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              />
            </div>
          </div>

          <div className="row mb-0">
            <label className="col-lg-4 col-form-label fw-bold fs-6 required">
              Tipe Produk
            </label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="form-check form-check-custom form-check-solid me-6">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_both"
                  id="flexRadioDefault2"
                  checked={formData.is_both}
                  onChange={() => handleChangeProductType(true)}
                />
                <label className="form-check-label">
                  Material dan Non-Material
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="radio"
                  name="is_both"
                  id="flexRadioDefault1"
                  checked={!formData.is_both}
                  onChange={() => handleChangeProductType(false)}
                />
                <label className="form-check-label">Non-Material</label>
              </div>
            </div>
          </div>

          <div className="row mb-0">
            <label className="col-lg-4 col-form-label fw-bold fs-6 required">
              Kurir Toko
            </label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                <input
                  name="is_free_shipping"
                  className="form-check-input h-30px w-60px"
                  type="checkbox"
                  checked={formData.is_free_shipping}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      is_free_shipping: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mb-0">
            <label className="col-lg-4 col-form-label fw-bold fs-6 required">
              Fitur Toko Libur
            </label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                <input
                  name="is_open"
                  className="form-check-input h-30px w-60px"
                  type="checkbox"
                  checked={!formData.is_open}
                  onChange={(e) =>
                    setFormData({ ...formData, is_open: !formData.is_open })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mb-0">
            <label className="col-lg-4 col-form-label fw-bold fs-6 required">
              Terverifikasi
            </label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                <input
                  name="is_verified"
                  className="form-check-input h-30px w-60px"
                  type="checkbox"
                  checked={formData.is_verified}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      is_verified: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? (
                <span className="indicator-progress">
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              ) : (
                "Simpan Perubahan"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
