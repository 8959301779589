import React, { useEffect, useRef, useState } from "react";
import {
  Offcanvas,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import Pusher from "pusher-js";
import { message as antdMessage, Image } from "antd";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";

type User = {
  id: number;
  type: string;
  name: string;
  photo: string;
};

type MessageData = {
  id: number;
  user: User;
  file: string | null;
  message: string;
  is_you: boolean;
  is_read: boolean;
  date: string;
};

interface DrawerChatComplainProps {
  show: boolean;
  onHide: () => void;
  id: number;
}

const DrawerChatComplain: React.FC<DrawerChatComplainProps> = ({
  show,
  onHide,
  id,
}) => {
  const [messages, setMessages] = useState<MessageData[]>([]);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const containerRef = useRef<HTMLDivElement>(null);
  const [messageInput, setMessageInput] = useState<string>("");
  const [uniqueUsers, setUniqueUsers] = useState<User[]>([]);
  const [fileInput, setFileInput] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string | null>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const pusher = new Pusher("b1850c2dae770ae81718", { cluster: "ap1" });

  useEffect(() => {
    if (id !== null) {
      resetChat();
      getDetailChat();
    }
  }, [id]);

  useEffect(() => {
    if (show) {
      scrollToBottom();
    }
  }, [show]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const channel = pusher.subscribe(`discussion.${id}`);

    channel.bind(`discussion.${id}`, (data: MessageData) => {
      setMessages((prevMessages) => [...prevMessages, { ...data }]);
    });

    return () => {
      pusher.unsubscribe(`discussion.${id}`);
    };
  }, [id]);

  const resetChat = () => {
    setMessages([]);
    setMeta({
      page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    });
  };

  const getDetailChat = async (page = 1) => {
    setLoading(true);
    const response = await API.GetChatComplainDetail(token, id, page, "9999");

    if (response.status === 200 && response?.data.data.length > 0) {
      setMessages((prevMessages) => [
        ...response.data.data.reverse(),
        ...prevMessages,
      ]);
      setMeta(response.data.meta);

      const uniqueUsers: User[] = Array.from(
        new Map<number, User>(
          response.data.data.map((item: { user: User }) => [
            item.user.id,
            item.user,
          ])
        ).values()
      );
      setUniqueUsers(uniqueUsers);
    }
    setLoading(false);
  };

  const formatDate = (date: Date) => {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  };

  const handleSendChat = async (e: React.FormEvent) => {
    e.preventDefault();
    if (messageInput.trim() === "") return;

    setLoading(true);

    const adminUser = uniqueUsers.find((user) => user.type === "admin");

    if (!adminUser) {
      antdMessage.error("Admin user not found");
      setLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("message", messageInput);
    if (fileInput) formData.append("file", fileInput);

    const resSendChat = await API.SendChatComplain(formData, token, id);

    if (resSendChat.status === 200) {
      setMessageInput("");
      setFileInput(null);
      setPreviewPhoto(null);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now(),
          user: {
            id: adminUser.id,
            type: "admin",
            name: adminUser.name,
            photo: adminUser.photo,
          },
          file: previewPhoto,
          message: messageInput,
          is_you: true,
          is_read: true,
          date: formatDate(new Date()),
        },
      ]);
      scrollToBottom();
    }
    setLoading(false);
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      const scrollHeight = containerRef.current.scrollHeight;
      const clientHeight = containerRef.current.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messages]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size / (1024 * 1024) <= MAX_FILE_SIZE_MB) {
        setFileInput(file);
        setFileType(file.type);
        setPreviewPhoto(URL.createObjectURL(file));
      }
    }
  };

  const isImageFileInput = (type: string) => type.startsWith("image/");
  const isVideoFileInput = (type: string) => type.startsWith("video/");

  const isImageFile = (url: string) => /\.(jpg|jpeg|png|gif)$/i.test(url);
  const isVideoFile = (url: string) => /\.(mp4|webm|ogg)$/i.test(url);

  const groupedMessages: { [key: string]: MessageData[] } = {};

  messages.forEach((message) => {
    const date = new Date(message.date).toLocaleDateString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }

    groupedMessages[date].push(message);
  });

  const handlePreviewClick = (fileUrl: string) => {
    setModalContent(fileUrl);
    setShowModal(true);
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });

      setShowScrollButton(false);
    }
  };

  const MAX_FILE_SIZE_MB = 5;

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement="end"
        className="offcanvas-end d-flex flex-column"
        style={{ width: "650px", maxWidth: "none", boxShadow: "none" }}
      >
        <div className="card w-100 rounded-0 d-flex flex-column h-100">
          <div className="card-header d-flex align-items-center">
            <div className="card-title d-flex align-items-center me-2">
              <div className="symbol-group symbol-hover">
                {uniqueUsers.map((user) => (
                  <OverlayTrigger
                    key={user.id}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${user.id}`}>{user.name}</Tooltip>
                    }
                  >
                    <div className="symbol symbol-35px symbol-circle">
                      <img
                        alt={`Pic of ${user.name}`}
                        src={
                          user.photo ||
                          toAbsoluteUrl("/media/avatars/300-5.jpg")
                        }
                      />
                    </div>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
            <button
              className="btn btn-sm btn-icon btn-active-light-danger"
              onClick={onHide}
            >
              <i className="ki-duotone ki-cross-square fs-1">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
              </i>
            </button>
          </div>
          <div className="card-body flex-grow-1 overflow-auto mb-0 pb-0">
            <div
              ref={containerRef}
              style={{
                height: "calc(100%)",
                overflowY: "scroll",
              }}
              onScroll={handleScroll}
            >
              <div className="chat-messages-container">
                {Object.entries(groupedMessages).map(([date, messages]) => (
                  <div key={date}>
                    <div className="text-center fw-bold text-uppercase pb-10">
                      <span className="badge badge-light">{date}</span>
                    </div>
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={clsx("d-flex mb-10", {
                          "justify-content-start": !message.is_you,
                          "justify-content-end": message.is_you,
                        })}
                      >
                        <div className="d-flex flex-column align-items">
                          <div
                            className={clsx("d-flex align-items-center mb-2", {
                              "flex-row-reverse": message.is_you,
                            })}
                          >
                            {!message.is_you && (
                              <>
                                <div className="symbol symbol-35px symbol-circle me-2">
                                  <img
                                    alt={`Pic of ${message.user.name}`}
                                    src={
                                      message.user.photo ||
                                      toAbsoluteUrl("/media/avatars/300-5.jpg")
                                    }
                                  />
                                </div>
                                <span className="fw-bold">
                                  {message.user.name}
                                </span>
                              </>
                            )}
                          </div>
                          <div
                            className={clsx(
                              "p-6 rounded",
                              {
                                "bg-light-info":
                                  message.user.type === "seller" &&
                                  !message.is_you,
                                "bg-light-primary":
                                  message.user.type === "admin" &&
                                  !message.is_you,
                                "bg-light-warning":
                                  message.user.type === "exp" &&
                                  !message.is_you,
                                "bg-light-danger":
                                  message.user.type === "buyer" &&
                                  !message.is_you,
                                "bg-light-success": message.is_you,
                              },
                              "text-dark fw-bold mw-lg-400px",
                              {
                                "text-start": !message.is_you,
                                "text-end": message.is_you,
                              }
                            )}
                          >
                            {message.file && (
                              <div className="position-relative mb-4">
                                {isImageFile(message.file) ? (
                                  <a
                                    onClick={() => {
                                      if (message.file) {
                                        handlePreviewClick(message.file);
                                      }
                                    }}
                                  >
                                    <Image
                                      className="w-100 h-100 object-fit-cover rounded"
                                      src={message.file}
                                      alt="Uploaded file"
                                      width={125}
                                      height={125}
                                      style={{ objectFit: "cover" }}
                                      preview={false}
                                    />
                                  </a>
                                ) : isVideoFile(message.file) ? (
                                  <div>
                                    <video
                                      className="w-100 h-100 object-fit-cover rounded"
                                      controls
                                      width={125}
                                      height={125}
                                    >
                                      <source
                                        src={message.file}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                ) : (
                                  <a
                                    href={message.file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button variant="link" className="mt-2">
                                      Open File
                                    </Button>
                                  </a>
                                )}
                              </div>
                            )}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: message.message,
                              }}
                            />
                            <span className="text-muted fs-7 mt-3">
                              {message.date.split(" ")[3]}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {/* {showScrollButton && (
                  <button
                    className="sticky-bottom btn-icon btn btn-light-primary "
                    onClick={scrollToBottom}
                  >
                    <i className="ki-duotone ki-arrow-down fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                    </i>
                  </button>
                )} */}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex align-items-center">
            <Form className="w-100" onSubmit={handleSendChat}>
              <div className="d-flex">
                <div className="d-flex align-items-center me-2">
                  <input
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileChange}
                    className="form-control form-control-flush mb-3"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <button
                    className="btn btn-icon btn-active-light-primary me-1"
                    type="button"
                    data-bs-toggle="tooltip"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <i className="bi bi-paperclip fs-3"></i>
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Masukkan pesan"
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                />
                <Button
                  type="submit"
                  className="btn btn-primary ms-2"
                  disabled={!messageInput.trim() && !fileInput}
                >
                  Kirim
                </Button>
              </div>
            </Form>

            {/* Preview of selected file */}
            {previewPhoto && fileType && (
              <div className="mt-2">
                {isImageFileInput(fileType) ? (
                  <Image
                    src={previewPhoto}
                    alt="Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : isVideoFileInput(fileType) ? (
                  <video
                    src={previewPhoto}
                    controls
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <div>Unsupported file type</div>
                )}
              </div>
            )}
          </div>
        </div>
      </Offcanvas>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Media Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalContent &&
            (isImageFile(modalContent) ? (
              <img
                src={modalContent}
                alt="Preview"
                style={{ width: "100%", height: "auto" }}
              />
            ) : isVideoFile(modalContent) ? (
              <video controls style={{ width: "100%", height: "auto" }}>
                <source src={modalContent} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div>Unsupported file type</div>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DrawerChatComplain;
