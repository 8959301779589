/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../auth";
import { useLocation } from "react-router-dom";
import RupiahFormat from "../../global/Formatrupiah";
import { Image, Spin } from "antd";
import apiProvider from "../../../../utils/apiProvider";
import TrackingDrawer from "./TrackingTransaction";
import ConversationDrawer from "./components/ConversationDrawer";

interface Shop {
  id: number;
  photo: string;
  name: string;
}

interface Product {
  id: number;
  photo: string;
  type: string;
  name: string;
  variant: string | null;
  note: string | null;
  qty: number;
  price: number;
  subtotal: number;
}

interface Shipping {
  courier: string;
  service: string;
  resi: string;
  name: string;
  phone: string;
  address: string;
}

interface Payment {
  bank: string;
  type: string;
  number: string | null;
  url: string | null;
  expired: string | null;
  status: string;
}

interface Conversation {
  buyer_id: number;
  buyer_conversation_id: string | null;
  seller_id: number;
  seller_conversation_id: string | null;
  expedition_id: string | null;
  expedition_conversation_id: string | null;
}

interface User {
  id: number;
  photo: string;
  name: string;
  phone: string;
  email: string;
}

interface Order {
  id: number;
  code: string;
  shop: Shop;
  attention: string | null;
  products: Product[];
  shipping: Shipping;
  payment: Payment;
  shipping_cost: number;
  extra_cost: number;
  subtotal: number;
  discount: number;
  cashback: number;
  fee: number;
  fee_payment: number;
  grand_total: number;
  status: string;
  nego_status: string;
  is_nego_shop: boolean;
  reason: string | null;
  cancel_by: string | null;
  date: string;
  conversation: Conversation;
  referral: {
    id: number;
    user: User;
    amount: number;
    percentage: string;
    bonus: number;
  } | null;
}

interface DetailTransactionUserProps {
  id: number;
  data: Order;
}

interface Trace {
  status: string;
  detail: string;
  date: string;
}

interface TrackingTransaction {
  id: number;
  resi: string;
  type: string;
  courier: string;
  service: string;
  trace: Trace[];
}

const TransactionDetail: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const state = location.state as DetailTransactionUserProps;
  const [loading, setLoading] = useState<boolean>(false);
  const [trackingTransaction, setTrackingTransaction] =
    useState<TrackingTransaction | null>(null);
  const [zeroData, setZeroData] = useState("");
  const { id } = state;
  const [showModalTracking, setShowModalTracking] = useState(false);
  const [show, setShow] = useState(false);
  const [activeConversationId, setActiveConversationId] = useState<
    string | null
  >(null);
  const [orderData, setOrderData] = useState<Order | null>(null);

  const [error, setError] = useState<string>("");
  // Fetch transaction details
  const fetchTransactionDetail = useCallback(async () => {
    if (!token || !id) return;

    setLoading(true);
    try {
      const response = await apiProvider.GetTransactionDetail(token, id);
      if (response.status === 200) {
        setOrderData(response.data.data);
        setError("");
      } else {
        setError(response?.message || "Failed to fetch transaction details");
        setOrderData(null);
      }
    } catch (err) {
      setError("An error occurred while fetching transaction details");
      setOrderData(null);
    } finally {
      setLoading(false);
    }
  }, [token, id]);

  // Fetch tracking data
  const fetchTrackingTransaction = useCallback(async () => {
    if (!token || !id) return;

    setLoading(true);
    try {
      const response = await apiProvider.TrackingTransaction(token, id);
      if (response.status === 200) {
        setTrackingTransaction(response.data.data);
        setZeroData("");
      } else {
        setTrackingTransaction(null);
        setZeroData(response?.message || "No tracking data available");
      }
    } catch (err) {
      setTrackingTransaction(null);
      setZeroData("Failed to fetch tracking data");
    } finally {
      setLoading(false);
    }
  }, [token, id]);

  useEffect(() => {
    fetchTransactionDetail();
    fetchTrackingTransaction();
  }, [fetchTransactionDetail, fetchTrackingTransaction]);

  const handleShowDrawer = (conversationId: string | null): void => {
    setActiveConversationId(conversationId);
    setShow(true);
  };

  const handleCloseDrawer = () => {
    setActiveConversationId(null);
  };

  if (error) {
    return (
      <div className="alert alert-danger">
        <div className="d-flex align-items-center">
          <i className="ki-duotone ki-information-5 fs-2 me-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <div className="d-flex flex-column">
            <h4 className="mb-1">Error</h4>
            <span>{error}</span>
          </div>
        </div>
      </div>
    );
  }

  if (loading || !orderData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        {loading ? <Spin tip="Loading..." /> : null}
      </div>
    );
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };

  const DetailRow = React.memo(
    ({ label, value }: { label: React.ReactNode; value: React.ReactNode }) => (
      <tr>
        <td className="text-muted">
          <div className="d-flex align-items-center">{label}</div>
        </td>
        <td className="fw-bold text-end">{value}</td>
      </tr>
    )
  );

  interface ReferralSectionProps {
    referral: {
      id: number;
      user: User;
      amount: number;
      percentage: string;
      bonus: number;
    };
  }

  const ReferralSection: React.FC<ReferralSectionProps> = React.memo(
    ({ referral }) => {
      if (!referral) return null;

      return (
        <div className="card card-flush py-4 mb-10">
          <div className="card-header">
            <div className="card-title">
              <h2>Referral</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">Pengguna</div>
                    </td>
                    <td className="fw-bold text-end">
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                          <div className="symbol-label">
                            <img
                              src={referral.user.photo}
                              alt={referral.user.name}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <span className="text-gray-600">
                          {referral.user.name}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <DetailRow
                    label="Nomor Telepon"
                    value={referral.user.phone}
                  />
                  <DetailRow label="Email" value={referral.user.email} />
                  <DetailRow
                    label="Subtotal"
                    value={<RupiahFormat value={referral.amount} />}
                  />
                  <DetailRow
                    label="Persentase"
                    value={`${referral.percentage}%`}
                  />
                  <DetailRow
                    label="Bonus"
                    value={<RupiahFormat value={referral.bonus} />}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  );

  const renderContactButtons = () => {
    const buttons = [];

    if (orderData.conversation.buyer_conversation_id) {
      buttons.push({
        id: orderData.conversation.buyer_conversation_id,
        label: "Hubungi Pembeli",
        className: "btn-danger",
      });
    }

    if (orderData.conversation.seller_conversation_id) {
      buttons.push({
        id: orderData.conversation.seller_conversation_id,
        label: "Hubungi Penjual",
        className: "btn-primary",
      });
    }

    if (orderData.conversation.expedition_conversation_id) {
      buttons.push({
        id: orderData.conversation.expedition_conversation_id,
        label: "Hubungi Ekspedisi",
        className: "btn-success",
      });
    }

    if (buttons.length === 0) return null;

    const colClass =
      buttons.length === 1
        ? "col-12"
        : buttons.length === 2
          ? "col-6"
          : "col-4";

    return (
      <div className="d-flex justify-content-end mb-8">
        <div className="row g-3 w-100">
          {buttons.map((button) => (
            <div key={button.id} className={colClass}>
              <button
                className={`btn ${button.className} w-100`}
                onClick={() => handleShowDrawer(button.id)}
              >
                {button.label}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Detail Pesanan</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <DetailRow label="Kode Transaksi" value={orderData.code} />
                <DetailRow label="Tanggal" value={formatDate(orderData.date)} />
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">Toko</div>
                  </td>
                  <td className="fw-bold text-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                        <div className="symbol-label">
                          <img
                            src={orderData.shop.photo}
                            alt="Shop"
                            className="w-100"
                          />
                        </div>
                      </div>
                      <span className="text-gray-600">
                        {orderData.shop.name}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Pengiriman</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <DetailRow
                  label={
                    <>
                      Resi
                      <span
                        className="ms-1"
                        orderData-bs-toggle="tooltip"
                        title="View the shipping manifest generated by this order."
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                      </span>
                    </>
                  }
                  value={
                    <>
                      <span className="text-gray-600">
                        {orderData.shipping.resi || "Resi belum tersedia!"}
                      </span>
                      {orderData.shipping.resi &&
                        orderData.shipping.courier !== "Kurir Toko" && (
                          <button
                            onClick={() => setShowModalTracking(true)}
                            className="btn btn-light-primary btn-sm ms-4"
                          >
                            Lacak
                          </button>
                        )}
                    </>
                  }
                />
                <DetailRow
                  label="Kurir"
                  value={`${orderData.shipping.courier} - ${orderData.shipping.service}`}
                />
                <DetailRow label="Penerima" value={orderData.shipping.name} />
                <DetailRow
                  label="Nomor Telepon"
                  value={orderData.shipping.phone}
                />
                <DetailRow
                  label="Alamat"
                  value={
                    <a className="text-gray-600">
                      {orderData.shipping.address}
                    </a>
                  }
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {orderData.referral && <ReferralSection referral={orderData.referral} />}
      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Pembayaran</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <DetailRow
                  label="Metode Pembayaran"
                  value={`${orderData.payment.bank} - ${orderData.payment.type}`}
                />
                <DetailRow
                  label={
                    <>
                      Status Pembayaran
                      <span
                        className="ms-1"
                        orderData-bs-toggle="tooltip"
                        title="Reward value earned by customer when purchasing this order"
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                      </span>
                    </>
                  }
                  value={
                    <div className="d-flex col-row justify-content-end align-items-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6 ms-4">
                        <span
                          className={`badge ${orderData.payment.status === "PENDING"
                              ? "badge-light-primary"
                              : orderData.payment.status === "SUCCESSFUL"
                                ? "badge-light-success"
                                : orderData.payment.status === "CANCELLED"
                                  ? "badge-light-warning"
                                  : orderData.payment.status === "FAILED"
                                    ? "badge-light-danger"
                                    : "badge-light-danger"
                            }`}
                        >
                          {orderData.payment.status === "PENDING"
                            ? "Menunggu"
                            : orderData.payment.status === "SUCCESSFUL"
                              ? "Sukses"
                              : orderData.payment.status === "CANCELLED"
                                ? "Dibatalkan"
                                : orderData.payment.status === "FAILED"
                                  ? "Gagal"
                                  : "-"}
                        </span>
                      </span>
                      {orderData.payment.bank !== "Baba" && (
                        <a
                          className="btn btn-light-primary btn-sm ms-4"
                          href={orderData.payment.url || undefined}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Klik disini
                        </a>
                      )}
                    </div>
                  }
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="tab-content mb-8">
        <div
          className="tab-pane fade show active"
          id="kt_ecommerce_sales_order_summary"
          role="tab-panel"
        >
          <div className="d-flex flex-column gap-7 gap-lg-10 mt-12">
            <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
              <div className="card-header">
                <div className="card-title">
                  <h2>Detail Produk</h2>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                      <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-175px">Product</th>
                        <th className="min-w-70px text-end">Qty</th>
                        <th className="min-w-100px text-end">Harga</th>
                        <th className="min-w-100px text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                      {!orderData.products.length ? (
                        loading ? (
                          <tr>
                            <td colSpan={8} className="text-center text-muted">
                              <span className="text-muted fw-bold mb-1 fs-6"></span>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center text-muted">
                              <span className="text-muted fw-bold mb-1 fs-6">
                                No products found
                              </span>
                            </td>
                          </tr>
                        )
                      ) : (
                        orderData.products.map((product, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="symbol symbol-50px">
                                    <Image
                                      className="symbol-label"
                                      src={product.photo}
                                    />
                                  </span>
                                  <div className="ms-5">
                                    <span className="fw-bold text-gray-600">
                                      {product.name}
                                    </span>
                                    {product.variant && (
                                      <div className="fw-bold text-gray-600 mt-1">
                                        {product.variant}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">{product.qty}</td>
                              <td className="text-end">
                                <RupiahFormat value={product.price} />
                              </td>
                              <td className="text-end">
                                <RupiahFormat
                                  value={product.qty * product.price}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      )}
                      <tr>
                        <td colSpan={3} className="text-end">
                          Subtotal
                        </td>
                        <td className="text-end">
                          <RupiahFormat value={orderData.subtotal} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-end">
                          Biaya Pengiriman
                        </td>
                        <td className="text-end">
                          <RupiahFormat value={orderData.shipping_cost} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-end">
                          Biaya Penanganan
                        </td>
                        <td className="text-end">
                          <RupiahFormat value={orderData.fee} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-end">
                          Biaya Pembayaran
                        </td>
                        <td className="text-end">
                          <RupiahFormat value={orderData.fee_payment} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-end">
                          Biaya Tambahan
                        </td>
                        <td className="text-end">
                          <RupiahFormat value={orderData.extra_cost} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="fs-3 text-gray-900 text-end">
                          Grand Total
                        </td>
                        <td className="text-gray-900 fs-3 fw-bolder text-end">
                          <RupiahFormat value={orderData.grand_total} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderContactButtons()}

      <TrackingDrawer
        trackingTransaction={trackingTransaction}
        show={showModalTracking}
        onHide={() => setShowModalTracking(false)}
      />

      <ConversationDrawer
        conversationId={activeConversationId}
        show={show}
        onHide={() => setShow(false)}
        onClose={handleCloseDrawer}
      />
    </>
  );
};

export { TransactionDetail };
