import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { KTSVG } from "../../../../../_metronic/helpers";
import clsx from "clsx";

interface UpdatePaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpdatePayment: (formData: any, id: number) => Promise<boolean>;
  id: number | null;
  payment?: {
    bank_type: string;
    bank_code: string;
    name: string;
    icon: string;
    fee: number;
    fee_percent: string;
    is_active: boolean;
  } | null;
}

const UpdatePaymentModal: React.FC<UpdatePaymentModalProps> = ({
  isOpen,
  onClose,
  handleUpdatePayment,
  id,
  payment,
}) => {
  const [formData, setFormData] = useState({
    bank_type: "",
    bank_code: "",
    name: "",
    icon: null as File | null,
    fee: 0,
    fee_percent: "0",
    is_active: false,
  });

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (payment) {
      setFormData({
        bank_type: payment.bank_type,
        bank_code: payment.bank_code,
        name: payment.name,
        icon: null,
        fee: payment.fee,
        fee_percent: payment.fee_percent,
        is_active: payment.is_active,
      });

      if (payment.icon) {
        setFileList([
          {
            uid: "-1",
            name: "icon",
            status: "done",
            url: payment.icon,
          },
        ]);
      }
    }
  }, [payment]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    let processedValue = value;

    // Handle numeric inputs
    if (name === "fee") {
      processedValue = value.replace(/^0+(\d)/, "$1"); // Remove leading zeros
    }

    setFormData((prev) => ({
      ...prev,
      [name]: processedValue,
    }));
  };

  const handleUploadChange = ({
    file,
    fileList,
  }: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    setFileList(fileList);

    if (file.status === "done" && file.originFileObj) {
      setFormData((prev) => ({
        ...prev,
        icon: file.originFileObj as RcFile,
      }));
    } else if (file.status === "removed") {
      setFileList([]);
      setFormData((prev) => ({
        ...prev,
        icon: null,
      }));
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting || !id) return;

    setIsSubmitting(true);
    const success = await handleUpdatePayment(formData, id);

    if (success) {
      handleClose();
    }
    setIsSubmitting(false);
  };

  const handleClose = () => {
    setFormData({
      bank_type: "",
      bank_code: "",
      name: "",
      icon: null,
      fee: 0,
      fee_percent: "0",
      is_active: false,
    });
    setFileList([]);
    onClose();
  };

  const isPaylater = formData.bank_type === "paylater_account";

  return isOpen ? (
    <>
      <div className="modal fade show d-block" role="dialog" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Edit Pembayaran</h2>
              <button
                className="btn btn-icon btn-sm btn-active-icon-primary"
                onClick={handleClose}
                type="button"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </button>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div className="fv-row mb-7">
                <label className="d-block fw-bold fs-6 mb-2">Icon</label>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleUploadChange}
                  onRemove={() => {
                    setFileList([]);
                    setFormData((prev) => ({ ...prev, icon: null }));
                  }}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => onSuccess && onSuccess("ok"), 0);
                  }}
                  accept="image/*"
                >
                  {fileList.length >= 1 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </div>

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Nama</label>
                <input
                  type="text"
                  name="name"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0"
                  )}
                  placeholder="Masukkan nama"
                  value={formData.name}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Tipe Bank</label>
                <select
                  name="bank_type"
                  className={clsx("form-select form-select-muted mb-3 mb-lg-0")}
                  value={formData.bank_type}
                  onChange={handleChange}
                  disabled
                >
                  <option value="" disabled>
                    Pilih Tipe Bank
                  </option>
                  <option value="baba_balance">Baba Balance</option>
                  <option value="bank_account">Bank Account</option>
                  <option value="virtual_account">Virtual Account</option>
                  <option value="wallet_account">Wallet Account</option>
                  <option value="paylater_account">Paylater Account</option>
                </select>
              </div>

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Biaya Penanganan {isPaylater ? "(%)" : "(Rp)"}
                </label>
                <input
                  type="number"
                  name={isPaylater ? "fee_percent" : "fee"}
                  className={clsx("form-control form-control-solid")}
                  placeholder={`Masukkan ${
                    isPaylater ? "persentase" : "nominal"
                  }`}
                  value={isPaylater ? formData.fee_percent : formData.fee}
                  onChange={handleChange}
                  min="0"
                  step={isPaylater ? "0.01" : "1"}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Menyimpan..." : "Simpan"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  ) : null;
};

export { UpdatePaymentModal };
