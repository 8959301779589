import { useState, useCallback } from "react";
import { RegionType } from "../components/type";
import apiProvider from "../../../../../utils/apiProvider";

export const useRegions = (token: string | undefined) => {
  const [regions, setRegions] = useState({
    province: [] as RegionType[],
    city: [] as RegionType[],
    district: [] as RegionType[],
    subdistrict: [] as RegionType[],
    zipCode: [] as RegionType[],
  });

  const [loading, setLoading] = useState(false);

  const fetchRegion = useCallback(
    async (
      type: string,
      provinceId?: number,
      cityId?: number,
      districtId?: number,
      subdistrictId?: number
    ) => {
      if (!token) return;

      setLoading(true);
      try {
        let endpoint = type;
        if (type === "city" && provinceId) {
          endpoint = `city?province=${provinceId}`;
        } else if (type === "district" && provinceId && cityId) {
          endpoint = `district?province=${provinceId}&city=${cityId}`;
        } else if (
          type === "sub-district" &&
          provinceId &&
          cityId &&
          districtId
        ) {
          endpoint = `sub-district?province=${provinceId}&city=${cityId}&district=${districtId}`;
        } else if (
          type === "zip-code" &&
          provinceId &&
          cityId &&
          districtId &&
          subdistrictId
        ) {
          endpoint = `zip-code?province=${provinceId}&city=${cityId}&district=${districtId}&sub-district=${subdistrictId}`;
        }

        const response = await apiProvider.GetRegion(token, endpoint);
        if (response.status === 200) {
          const data = response.data.data.map((item: any) => ({
            id: parseInt(item.id),
            name: item.value,
            lat: item.lat,
            lng: item.lng,
          }));

          setRegions((prev) => ({
            ...prev,
            [type.replace("-", "")]: data,
          }));

          return data;
        }
        return [];
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  return {
    regions,
    loading,
    fetchRegion,
  };
};
