import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { FC } from "react";

interface UpdateKategoriModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpateCategory: (formData: any, id: number) => void;
  id: number | null;
  category?: {
    id: number;
    type: string;
    name: string;
    icon: string;
  } | null;
}
const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");

const UpdateKategoriModal: FC<UpdateKategoriModalProps> = ({
  isOpen,
  onClose,
  handleUpateCategory,
  id,
  category,
}) => {
  const [formData, setFormData] = useState({
    type: category?.type || "",
    name: category?.name || "",
    icon: category?.icon ? new File([], category.icon) : null,
    iconURL: category?.icon || "",
  });

  useEffect(() => {
    setFormData({
      type: category?.type || "",
      name: category?.name || "",
      icon: null,
      iconURL: category?.icon || "",
    });
  }, [category]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = async () => {
          const compressWithSize = async (
            targetSizeKB: number
          ): Promise<File> => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const targetSize = targetSizeKB * 1024;

            const scaleFactor = Math.sqrt(targetSize / file.size);

            let width = img.width * scaleFactor;
            let height = img.height * scaleFactor;

            const minDimension = 200;
            if (width < minDimension || height < minDimension) {
              const aspectRatio = width / height;
              if (width < height) {
                width = minDimension;
                height = minDimension / aspectRatio;
              } else {
                height = minDimension;
                width = minDimension * aspectRatio;
              }
            }

            width = Math.floor(width);
            height = Math.floor(height);

            canvas.width = width;
            canvas.height = height;

            if (ctx) {
              ctx.imageSmoothingEnabled = true;
              ctx.imageSmoothingQuality = "high";
              ctx.drawImage(img, 0, 0, width, height);
            }

            return new Promise((resolve, reject) => {
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const compressedFile = new File([blob], file.name, {
                      type: file.type,
                      lastModified: Date.now(),
                    });
                    resolve(compressedFile);
                  } else {
                    reject(new Error("Canvas to Blob conversion failed"));
                  }
                },
                file.type,
                1.0
              );
            });
          };

          try {
            let targetSize = 95;
            let compressedFile = await compressWithSize(targetSize);

            while (compressedFile.size > 100 * 1024) {
              targetSize = targetSize * 0.9; // Reduce by 10% each iteration
              compressedFile = await compressWithSize(targetSize);
            }

            resolve(compressedFile);
          } catch (error) {
            reject(error);
          }
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files && files[0]) {
      try {
        const file = files[0];
        let finalFile = file;

        if (file.size > 64 * 1024) {
          finalFile = await compressImage(file);
        }

        const fileURL = URL.createObjectURL(finalFile);
        setFormData({
          ...formData,
          icon: finalFile,
          iconURL: fileURL,
        });
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    await handleFileUpload(e.dataTransfer.files);
  };

  const handleIconChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await handleFileUpload(e.target.files);
  };

  const handleClose = () => {
    setFormData({
      type: "",
      name: "",
      icon: null,
      iconURL: "",
    });
    onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className="modal fade show d-block"
            id="kt_modal_add_user"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="fw-bolder">Edit Kategori</h2>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm btn-active-icon-primary"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <form id="kt_modal_add_user_form" className="form" noValidate>
                    <div
                      className="fv-row mb-7"
                      id="drop-zone"
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <label className="d-block fw-bold fs-6 mb-2 required">
                        Ikon
                      </label>
                      <div
                        className="image-input image-input-outline"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage: `url('${
                            formData.icon || blankImg
                          }')`,
                        }}
                      >
                        <div
                          className="image-input-wrapper w-125px h-125px"
                          style={{
                            borderRadius: "100%",

                            backgroundImage: `url('${
                              formData.iconURL || blankImg
                            }')`,
                          }}
                        ></div>
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Change avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7"></i>
                          <input
                            type="file"
                            name="icon"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleIconChange}
                          />
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          title="Cancel avatar"
                        >
                          <i className="bi bi-x fs-2"></i>
                        </span>
                      </div>
                      <div className="form-text">
                        Tipe file yang diizinkan: png, jpg, jpeg.
                      </div>
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Tipe Kategori
                      </label>
                      <select
                        className="form-select form-select-solid"
                        aria-label="Select example"
                        value={formData.type}
                        onChange={(e) =>
                          setFormData({ ...formData, type: e.target.value })
                        }
                      >
                        <option value="">Pilih Tipe Kategori</option>
                        <option
                          value="material"
                          selected={formData.type === "material"}
                        >
                          Material
                        </option>
                        <option
                          value="non-material"
                          selected={formData.type === "non-material"}
                        >
                          Non-Material
                        </option>
                        <option
                          value="ppob"
                          selected={formData.type === "ppob"}
                        >
                          PPOB
                        </option>
                      </select>
                    </div>

                    {/* <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Tipe Kategori</label>
                      <input
                        placeholder='Masukkan nama kategori'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        type='text'
                        name='type'
                        value={formData.type}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                    </div> */}

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Nama Kategori
                      </label>
                      <input
                        placeholder="Masukkan nama kategori"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      if (id !== null) {
                        await handleUpateCategory(formData, id);
                        onClose();
                      }
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export { UpdateKategoriModal };
