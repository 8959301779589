/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import { useLocation } from "react-router-dom";
import RupiahFormat from "../../global/Formatrupiah";
import { Image, Spin } from "antd";
import DrawerChatComplain from "./DrawerChatComplain";
import apiProvider from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Modal } from "react-bootstrap";
import VideoModal from "./components/VideoModal";

interface Shop {
  id: number;
  photo: string;
  name: string;
}

interface Product {
  id: number;
  photo: string;
  type: string;
  name: string;
  variant: string | null;
  note: string | null;
  qty: number;
  price: number;
  width: number | null;
  length: number | null;
  height: number | null;
  volume: number | null;
  weight: string;
  subtotal: number;
}

interface ComplainTransaction {
  id: number;
  code: string;
  shop: Shop;
  products: Product[];
  discussion_available: boolean;
  discussion_count: number;
  type: string;
  problem: string;
  reason: string;
  file: string | null;
  solution: string;
  shipping: string | null;
  nominal: number;
  status: string;
  date: string;
}

type User = {
  id: number;
  type: string;
  name: string;
  photo: string;
};

type MessageData = {
  id: number;
  user: User;
  file: string | null;
  message: string;
  is_you: boolean;
  is_read: boolean;
  date: string;
};

const statusComplainLabels: {
  [key: string]: { label: string; color: string };
} = {
  approve: {
    label: "Menunggu Pengiriman Pembeli",
    color: "badge-light-warning",
  },
  decline: { label: "Menunggu Konfirmasi Admin", color: "badge-light-primary" },
  cancel: { label: "Pembatalan", color: "badge-light-danger" },
  pending: { label: "Menunggu Tindakan", color: "badge-light-secondary" },
  process: { label: "Barang Dikemas", color: "badge-light-info" },
  resend: { label: "Selesai", color: "badge-light-info" },
  success: { label: "Pembatalan", color: "badge-light-success" },
};

interface DetailTransactionUserProps {
  id: number;
  data: ComplainTransaction;
}

const ComplainDetail: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const state = location.state as DetailTransactionUserProps;
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { id, data } = state;

  const status = data?.status;
  const statusLabel = status ? statusComplainLabels[status]?.label : undefined;
  const statusColor = status ? statusComplainLabels[status]?.color : undefined;

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }

    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showAlert, showAlertError]);

  const formatToRupiah = (price: number) => {
    const formatted = `${Number(price).toLocaleString("id-ID")}`;
    return formatted;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const formatCurrencyIDR = (value: string | number | null): string => {
    if (value === null || value === 0) {
      return "0";
    }
    const numberValue = typeof value === "string" ? parseFloat(value) : value;
    if (isNaN(numberValue)) {
      return "0";
    }
    return new Intl.NumberFormat("id-ID", {
      style: "decimal",
      maximumFractionDigits: 0,
    }).format(numberValue);
  };

  const [showModalChat, setShowModalChat] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleClick = () => {
    if (data?.discussion_available) {
      setShowDrawer(true);
    } else {
      setShowModalChat(true);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };
  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <DrawerChatComplain
        show={showDrawer}
        onHide={() => setShowDrawer(false)}
        id={id}
      />

      <div className="card card-flush py-4 mb-10">
        <div className="card-body flex-column p-8">
          <div className="card-title d-flex justify-content-between align-items-center">
            <h2>Status Pesanan</h2>
            <div>
              <span className={`badge ${statusColor || ""} p-4`}>
                {status === "process" && data?.solution !== "replace"
                  ? "Menunggu Aksi Seller"
                  : statusLabel || status}
              </span>
            </div>
          </div>
        </div>

        <div className="card-body pt-0">
          <div className="separator border-gray-200"></div>
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-key fs-2 me-2">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      No. Pesanan
                    </div>
                  </td>
                  <td className="fw-bold text-end">{data?.code || 0}</td>
                </tr>
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-courier fs-2 me-2 ">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Tanggal
                    </div>
                  </td>
                  <td className="fw-bold text-end">{formatDate(data.date)}</td>
                </tr>
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-shop fs-2 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                      </i>
                      Toko
                    </div>
                  </td>

                  <div className="d-flex align-items-center justify-content-end">
                    <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                      <div className="symbol-label">
                        <img
                          src={data.shop.photo}
                          alt="Dan Wilson"
                          className="w-100"
                        />
                      </div>
                    </div>
                    <span className="text-gray-600">{data.shop.name}</span>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>Detail Komplain</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle mb-0 fs-6 gy-5 min-w-300px">
              <tr>
                <td className="fw-bold fs-4">
                  {data?.solution === "replace"
                    ? "Pengajuan Penukaran Barang"
                    : "Pengajuan Refund"}
                </td>
              </tr>
              <tr>
                <td className="fw-bold fs-6 text-gray-600">
                  {data?.solution === "replace"
                    ? "Pembeli ingin menukar produk sesuai pesanan"
                    : "Pembeli ingin mengajukan refund pesanan"}
                </td>
              </tr>
            </table>
            <div className="separator border-gray-200 mb-0"></div>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
              <thead>
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-175px">Product</th>
                  <th className="min-w-70px text-end">Type</th>
                  <th className="min-w-70px text-end">Qty</th>
                  {data?.products?.some((product) => product.volume) && (
                    <th className="min-w-70px text-end">Volume</th>
                  )}
                  <th className="min-w-70px text-end">Berat</th>
                  {data?.products?.some((product) => product.note) && (
                    <th className="min-w-70px text-end">Catatan</th>
                  )}
                  <th className="min-w-100px text-end">Harga</th>
                  <th className="min-w-100px text-end">Total</th>
                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {loading ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : data && data?.products.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center text-muted">
                      <span className="text-muted fw-bold mb-1 fs-6">
                        No products found
                      </span>
                    </td>
                  </tr>
                ) : data ? (
                  data.products.map((product, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="symbol symbol-50px">
                            <Image
                              className="symbol-label"
                              src={product.photo}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </span>
                          <div className="ms-5">
                            <span className="fw-bold text-gray-600">
                              {product.name}
                            </span>
                            {product.variant && (
                              <div className="fw-bold text-gray-600 mt-1">
                                {product.variant}
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        <span className="badge badge-light-primary p-4">
                          {product.type === "material"
                            ? "Material"
                            : "Non-Material"}
                        </span>
                      </td>

                      <td className="text-end">
                        {formatCurrencyIDR(product.qty)}
                      </td>
                      {product.note && (
                        <td className="text-end">{product.volume}</td>
                      )}
                      <td className="text-end">
                        {formatCurrencyIDR(product.weight)}
                      </td>
                      {product.note && (
                        <td className="text-end">{product.note}</td>
                      )}
                      <td className="text-end">
                        {"Rp " + formatToRupiah(product.price)}
                      </td>
                      <td className="text-end">
                        {"Rp " + formatToRupiah(product.qty * product.price)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center text-muted">
                      <span className="text-muted fw-bold mb-1 fs-6">
                        No data available
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card card-flush py-4 mb-10">
        <div className="card-header">
          <div className="card-title">
            <h2>{`Produk Bermasalah`}</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody className="fw-semibold text-gray-600">
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">Alasan</div>
                  </td>
                  <td className="fw-bold text-end">{data?.problem}</td>
                </tr>

                {data.file && (
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">Bukti</div>
                    </td>

                    <td className="fw-bold text-end">
                      <button
                        className="btn btn-light-primary btn-sm"
                        onClick={() => setShowVideoModal(true)}
                      >
                        Lihat Bukti
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <VideoModal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        videoUrl={data?.file || ""}
      />
      {data.discussion_available && (
        <div className="d-flex justify-content-end mb-8">
          <button className="btn btn-danger w-100" onClick={handleClick}>
            Kirim Pesan
          </button>
        </div>
      )}
    </>
  );
};

export { ComplainDetail };
