import React from "react";
import { Link, useLocation } from "react-router-dom";

interface ChatHeaderProps {
  onRoleChange: (role: string) => void;
}

const NAV_ITEMS = [
  {
    role: "buyer",
    label: "Pembeli",
    path: "/marketplace/percakapan/pembeli",
    icon: "bi-chat-text",
  },
  {
    role: "seller",
    label: "Penjual",
    path: "/marketplace/percakapan/penjual",
    icon: "bi-shop",
  },
  {
    role: "exp",
    label: "Ekspedisi",
    path: "/marketplace/percakapan/ekspedisi",
    icon: "bi-truck",
  },
];

export const ChatHeader: React.FC<ChatHeaderProps> = ({ onRoleChange }) => {
  const location = useLocation();

  return (
    <div className="card border-0 mb-10">
      <div className="card-header py-3">
        <h5 className="card-title fw-bold text-center m-0">List Chat</h5>
      </div>

      <div className="card-body p-2">
        <div className="d-flex justify-content-around align-items-center">
          {NAV_ITEMS.map(({ role, label, path, icon }) => (
            <Link
              key={role}
              to={path}
              className={`text-decoration-none text-center px-3 py-2 ${
                location.pathname.includes(path)
                  ? "text-primary fw-bold"
                  : "text-secondary"
              }`}
              onClick={() => onRoleChange(role)}
            >
              <i
                className={`bi ${icon} fs-4 mb-1 ${
                  location.pathname.includes(path)
                    ? "text-primary"
                    : "text-muted"
                }`}
              ></i>
              <div className="fs-6">{label}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
