import React from "react";
import { Modal } from "react-bootstrap";

const VideoModal: React.FC<{
  show: boolean;
  onHide: () => void;
  videoUrl: string;
}> = React.memo(({ show, onHide, videoUrl }) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    size="lg"
    aria-labelledby="video-modal"
  >
    <Modal.Header closeButton>
      <Modal.Title id="video-modal">Video Bukti</Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0">
      <iframe
        width="100%"
        height="400px"
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video Bukti"
      />
    </Modal.Body>
  </Modal>
));

export default VideoModal;
