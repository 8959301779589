// utils/formatters.ts

/**
 * Formats a timestamp into a human-readable "time ago" string
 * @param dateString - ISO date string or timestamp
 * @returns Formatted string like "2 minutes ago", "3 hours ago", etc.
 */
export const timeAgo = (dateString: string): string => {
  const now = new Date();
  const date = new Date(dateString);
  const diffInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60)
  );

  if (diffInMinutes < 1) return "Baru saja";
  if (diffInMinutes < 60) return `${diffInMinutes} menit yang lalu`;
  if (diffInMinutes < 1440)
    return `${Math.floor(diffInMinutes / 60)} jam yang lalu`;
  if (diffInMinutes < 10080)
    return `${Math.floor(diffInMinutes / 1440)} hari yang lalu`;
  if (diffInMinutes < 43200)
    return `${Math.floor(diffInMinutes / 10080)} minggu yang lalu`;
  return `${Math.floor(diffInMinutes / 43200)} bulan yang lalu`;
};

/**
 * Formats a number into Indonesian Rupiah currency format
 * @param price - Number to format
 * @returns Formatted string like "Rp 123.456"
 */
export const formatToRupiah = (price: number): string => {
  return `Rp ${Number(price).toLocaleString("id-ID")}`;
};

/**
 * Formats a date into a localized time string
 * @param date - Date to format
 * @returns Formatted time string like "14:30"
 */
export const formatTime = (date: string): string => {
  return new Date(date).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

/**
 * Formats a date into a localized date string
 * @param date - Date to format
 * @returns Formatted date string like "12 Jan 2024"
 */
export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString("id-ID", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

/**
 * Formats a file size in bytes to a human-readable string
 * @param bytes - Number of bytes
 * @returns Formatted string like "1.5 MB"
 */
export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
};

/**
 * Truncates text to a specified length and adds ellipsis
 * @param text - Text to truncate
 * @param maxLength - Maximum length before truncating
 * @returns Truncated text with ellipsis if needed
 */
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};
