import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChatHeader } from "./ChatHeader";
import { ListChat } from "./components/ListChat";

const ChatPage: FC = () => {
  const location = useLocation();
  const [currentRole, setCurrentRole] = useState<string>("");

  useEffect(() => {
    // Get initial role from URL path
    if (location.pathname.includes("/pembeli")) {
      setCurrentRole("buyer");
    } else if (location.pathname.includes("/penjual")) {
      setCurrentRole("seller");
    } else if (location.pathname.includes("/ekspedisi")) {
      setCurrentRole("exp");
    }
  }, [location.pathname]);

  const handleRoleChange = (role: string) => {
    setCurrentRole(role);
  };

  return (
    <>
      <ChatHeader onRoleChange={handleRoleChange} />
      {currentRole && <ListChat role={currentRole} />}
    </>
  );
};

export { ChatPage };
