import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Spin } from "antd";

interface Fee {
  id: number;
  label: string;
  cost: number | null;
  percent: string | null;
}

interface AlertState {
  show: boolean;
  type: "success" | "error";
  message: string;
}

const validationSchema = Yup.object().shape({
  cost: Yup.number().nullable().min(0, "Biaya tidak boleh kurang dari 0"),
  percent: Yup.number()
    .nullable()
    .min(0, "Persentase tidak boleh kurang dari 0")
    .max(100, "Persentase tidak boleh lebih dari 100"),
});

interface FeeFormProps {
  fee: Fee;
  onSubmit: (values: any) => Promise<void>;
  isLoading: boolean;
}

const FeeForm: React.FC<FeeFormProps> = React.memo(
  ({ fee, onSubmit, isLoading }) => {
    const isCost = fee.cost !== null;
    const fieldName = isCost ? "cost" : "percent";

    const formatCurrency = (value: number) => {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(value);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // Remove leading zeros and get raw input value
      const rawValue = e.target.value.replace(/^0+(\d)/, "$1");
      let value = parseFloat(rawValue.replace(/[^\d]/g, ""));

      if (!isCost && !isNaN(value)) {
        value = Math.min(100, Math.max(0, value));
      } else if (isCost && !isNaN(value)) {
        value = Math.max(0, value);
      }

      formik.setFieldValue(fieldName, isNaN(value) ? 0 : value);
    };

    const formik = useFormik({
      initialValues: {
        label: fee.label,
        [fieldName]: isCost
          ? fee.cost
          : fee.percent
          ? parseFloat(fee.percent)
          : null,
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        const updateData = {
          label: fee.label,
          ...(isCost
            ? { cost: values.cost, percent: null }
            : { cost: null, percent: values.percent?.toString() }),
        };
        await onSubmit(updateData);
      },
    });

    return (
      <form onSubmit={formik.handleSubmit} className="py-3">
        <div className="mb-3">
          <label className="form-label fw-bold">
            {isCost ? "Biaya" : "Persentase"}
          </label>
          <div className="position-relative">
            <input
              type="text"
              name={fieldName}
              value={
                isCost
                  ? formik.values[fieldName] === 0
                    ? "Rp 0"
                    : formatCurrency(formik.values[fieldName] as number)
                  : formik.values[fieldName] ?? ""
              }
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              className={`form-control form-control-lg ${
                formik.errors[fieldName] && formik.touched[fieldName]
                  ? "is-invalid"
                  : ""
              }`}
              placeholder={`Masukkan ${isCost ? "biaya" : "persentase"}...`}
              disabled={isLoading}
              min="0"
              max={isCost ? undefined : "100"}
              step="any"
            />
            {formik.errors[fieldName] && formik.touched[fieldName] && (
              <div className="invalid-feedback">
                {formik.errors[fieldName]?.toString()}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary me-2 px-6"
          >
            {isLoading ? "Menyimpan..." : "Simpan Perubahan"}
          </button>
        </div>
      </form>
    );
  }
);

const getLabelText = (label: string): string => {
  const labelMap: Record<string, string> = {
    Transaction: "Transaksi",
    Expedition: "Ekspedisi",
    Referral: "Referral",
  };
  return labelMap[label] || label;
};

const FeeManagement: React.FC = () => {
  const { currentUser } = useAuth();
  const [fees, setFees] = useState<Fee[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState<number | null>(null);
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    type: "success",
    message: "",
  });

  const showAlert = useCallback(
    (type: "success" | "error", message: string) => {
      setAlert({
        show: true,
        type,
        message,
      });
    },
    []
  );

  const fetchFees = useCallback(async () => {
    if (!currentUser?.token) return;

    const response = await API.GetFee(currentUser.token);

    if (response?.status === 200 && response.data.data.length > 0) {
      setFees(response.data.data);
    } else {
      showAlert("error", "Gagal mengambil data: Data tidak tersedia");
    }

    setInitialLoading(false);
  }, [currentUser?.token, showAlert]);

  const handleUpdateFee = useCallback(
    async (id: number, updateData: any) => {
      if (!currentUser?.token) return;

      setLoadingSubmit(id);
      const response = await API.UpdateFee(updateData, currentUser.token, id);

      if (response?.status === 200) {
        showAlert("success", response.data.message);
        setFees((fees) =>
          fees.map((fee) => (fee.id === id ? { ...fee, ...updateData } : fee))
        );
      } else {
        showAlert("error", response?.message || "Gagal mengupdate data");
      }

      setLoadingSubmit(null);
    },
    [currentUser?.token, showAlert]
  );

  useEffect(() => {
    fetchFees();
  }, [fetchFees]);

  useEffect(() => {
    if (alert.show) {
      const timer = setTimeout(() => {
        setAlert((prev) => ({ ...prev, show: false }));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert.show]);

  if (initialLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-h-[400px]">
        <Spin />
      </div>
    );
  }

  return (
    <div className="container-fluid py-3">
      {alert.show && <AlertMessage type={alert.type} message={alert.message} />}

      <div className="row g-4">
        {fees.map((fee) => (
          <div key={fee.id} className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{getLabelText(fee.label)}</h5>
              </div>
              <div className="card-body">
                <FeeForm
                  fee={fee}
                  onSubmit={(values) => handleUpdateFee(fee.id, values)}
                  isLoading={loadingSubmit === fee.id}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { FeeManagement };
