import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import { TokoWrapper } from "../pages/marketplace/TokoWrapper";
import { KategoriWrapper } from "../pages/marketplace/KategoriWrapper";
import { AllProdukWrapper } from "../pages/marketplace/AllProdukWrapper";
import { TransaksiWrapper } from "../pages/marketplace/TransaksiWrapper";
import { PenarikanWrapper } from "../pages/marketplace/PenarikanWrapper";
import { AkunWrapper } from "../pages/settings/Akun";
import { SubCategory } from "../modules/marketplace/category/SubCategory";
import { SubSubCategory } from "../modules/marketplace/category/SubSubCategory";
import { VersionWrapper } from "../pages/settings/Version";
import { NotificationWrapper } from "../pages/settings/Notification";
import { FeeWrapper } from "../pages/settings/Fee";
import { BannerWrapper } from "../pages/settings/Banner";
import { UpdateShop } from "../modules/marketplace/shop/UpdateShop";
import PageEditProduct from "../modules/marketplace/products/PageEditProduct";
import { ReportList } from "../modules/marketplace/report/ReportList";
import { TransactionDetail } from "../modules/marketplace/transaction/TransactionDetail";
import UsersPage from "../modules/marketplace/user-management/UsersPage";
import TransactionUser from "../modules/accounts/components/transaction/TransactionUser";
import PriceExpedition from "../modules/accounts/components/price/PriceExpedition";
import { DetailPriceExpedition } from "../modules/accounts/components/price/DetailPriceExpedition";
import { ComplainTransaction } from "../modules/marketplace/complaintransaction/ComplainTransaction";
import { PaymentWrapper } from "../pages/settings/Payment";
import { ComplainDetail } from "../modules/marketplace/complaintransaction/Complaindetail";
import { LogWrapper } from "../pages/logger/LogWrapper";
import { Calendar } from "../pages/settings/Calendar";
import { ChatPage } from "../modules/chat/Chatpage";
import ShopManagement from "../modules/marketplace/shop/components/ShopTabs";

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="login/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="pengguna" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}

        {/* Utama */}
        <Route
          path="/pengguna/pembeli/transaksi"
          element={<TransactionUser />}
        />
        <Route
          path="/pengguna/penjual/transaksi"
          element={<TransactionUser />}
        />
        <Route path="/pengguna/ekspedisi/price" element={<PriceExpedition />} />
        <Route
          path="/pengguna/ekspedisi/price/:id"
          element={<DetailPriceExpedition />}
        />
        <Route
          path="/pengguna/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Marketplace */}
        <Route
          path="marketplace/toko"
          element={
            <SuspensedView>
              <TokoWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="/marketplace/toko/edit-toko"
          element={<ShopManagement />}
        />

        <Route
          path="marketplace/kategori"
          element={
            <SuspensedView>
              <KategoriWrapper />
            </SuspensedView>
          }
        />
        <Route path="/marketplace/sub-kategori" element={<SubCategory />} />
        <Route
          path="/marketplace/sub-sub-kategori"
          element={<SubSubCategory />}
        />

        <Route
          path="marketplace/semuaproduk"
          element={
            <SuspensedView>
              <AllProdukWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="marketplace/semuaproduk/edit-produk"
          element={<PageEditProduct />}
        />

        <Route
          path="marketplace/transaksi"
          element={
            <SuspensedView>
              <TransaksiWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="marketplace/transaksi/detail/:id"
          element={<TransactionDetail />}
        />

        <Route
          path="marketplace/komplaintransaksi"
          element={
            <SuspensedView>
              <ComplainTransaction />
            </SuspensedView>
          }
        />
        <Route
          path="marketplace/komplaintransaksi/detail/:id"
          element={<ComplainDetail />}
        />

        <Route
          path="marketplace/percakapan/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="marketplace/penarikan"
          element={
            <SuspensedView>
              <PenarikanWrapper />
            </SuspensedView>
          }
        />
        <Route path="marketplace/laporanpendapatan" element={<ReportList />} />

        {/* End Marketplace */}

        {/* Pengaturan */}
        <Route
          path="pengaturan/pemberitahuan"
          element={
            <SuspensedView>
              <NotificationWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="pengaturan/biayapenanganan"
          element={
            <SuspensedView>
              <FeeWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="pengaturan/pembayaran"
          element={
            <SuspensedView>
              <PaymentWrapper />
            </SuspensedView>
          }
        />
        <Route
          path="pengaturan/banner"
          element={
            <SuspensedView>
              <BannerWrapper />
            </SuspensedView>
          }
        />

        <Route
          path="pengaturan/versi"
          element={
            <SuspensedView>
              <VersionWrapper />
            </SuspensedView>
          }
        />

        <Route
          path="pengaturan/kalenderbaba"
          element={
            <SuspensedView>
              <Calendar />
            </SuspensedView>
          }
        />
        <Route
          path="pengaturan/akun"
          element={
            <SuspensedView>
              <AkunWrapper />
            </SuspensedView>
          }
        />

        {/* End Pengaturan */}

        {/* Log */}
        <Route
          path="log"
          element={
            <SuspensedView>
              <LogWrapper />
            </SuspensedView>
          }
        />
        {/* end Log */}
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/500" />} />

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
