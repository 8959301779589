import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { UpdateShop } from "../UpdateShop";
import { useAuth } from "../../../auth";
import apiProvider from "../../../../../utils/apiProvider";
import { ShopData } from "./type";
import { Image } from "antd";
import { formatToRupiah } from "../../../../../utils/formatters";
import { AlertMessage } from "../../../global/AlertMessage";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { DeleteModalProduct } from "../../../global/DeleteModalProduct";

type TransactionStatus = "success" | "cancelled";
type BalanceType = "credit" | "debit";

interface Product {
  readonly id: number;
  photo: string;
  name: string;
  price: number | string;
  stock: number;
  sold: number;
  is_active: boolean;
}

interface TransactionProduct {
  readonly id: number;
  photo: string;
  name: string;
  qty: number;
}

interface Transaction {
  readonly id: number;
  code: string;
  products: ReadonlyArray<TransactionProduct>;
  income: number;
  status: TransactionStatus;
  date: string;
}

interface BalanceHistory {
  readonly id: number;
  type: BalanceType;
  code: string;
  amount: number;
  remark: string;
  date: string;
}

interface Data {
  products: ReadonlyArray<Product>;
  transactions: ReadonlyArray<Transaction>;
  balance_histories: ReadonlyArray<BalanceHistory>;
}

interface TabConfig {
  readonly id: TabId;
  readonly label: string;
}

type TabId = "edit" | "products" | "transactions" | "balance";

const formatPrice = (price: string | null) => {
  if (!price) {
    return "";
  }
  const [min, max] = price.split(" - ");
  const formattedMin = `Rp ${Number(min).toLocaleString("id-ID")}`;
  const formattedMax = `Rp ${Number(max).toLocaleString("id-ID")}`;
  return `${formattedMin} - ${formattedMax}`;
};

const formatDate = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString("id-ID", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const TABS: ReadonlyArray<TabConfig> = [
  { id: "edit", label: "Edit Toko" },
  { id: "products", label: "Produk Toko" },
  { id: "transactions", label: "Transaksi Toko" },
  { id: "balance", label: "Histori Saldo Toko" },
] as const;

const LoadingSpinner: React.FC = () => (
  <div className="card shadow-sm">
    <div className="card-body p-4 text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
);

const ProductsTable: React.FC<{
  products: ReadonlyArray<Product>;
  onStatusChange: (success: boolean, message: string) => void;
}> = ({ products, onStatusChange }) => {
  const { currentUser } = useAuth();
  const [allProductData, setAllProductData] =
    useState<ReadonlyArray<Product>>(products);
  const [loading, setLoading] = useState(false);

  const handleToggleActive = async (id: number) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id[]", id.toString());

    try {
      const response = await apiProvider.UpdateStatusProduct(
        currentUser?.token,
        formData
      );

      if (response.status === 200) {
        setAllProductData((prevData) => {
          return prevData.map((product) => {
            if (product.id === id) {
              return { ...product, is_active: !product.is_active };
            }
            return product;
          });
        });
        onStatusChange(true, response.data.message);
      } else {
        onStatusChange(false, response.data.message);
      }
    } catch (error) {
      onStatusChange(false, "Terjadi kesalahan saat mengubah status produk");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProduct = async (ids: number[]) => {
    setLoading(true);
    const formData = new FormData();
    ids.forEach((id) => formData.append("id[]", id.toString()));

    try {
      const response = await apiProvider.DeleteProduct(
        currentUser?.token,
        formData
      );

      if (response.status === 200) {
        setAllProductData((prevData) =>
          prevData.filter((product) => !ids.includes(product.id))
        );
        onStatusChange(true, response.data.message);
      } else {
        onStatusChange(false, response.data.message);
      }
    } catch (error) {
      onStatusChange(false, "Terjadi kesalahan saat menghapus produk");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card shadow-sm">
      <div className="card-header py-4 d-flex justify-content-between align-items-center">
        <h3 className="card-title fw-bold m-0">Produk Toko</h3>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="min-w-125px">Produk</th>
                <th className="min-w-125px">Harga</th>
                <th className="min-w-125px">Stok</th>
                <th className="min-w-125px">Terjual</th>
                <th className="min-w-125px">Status</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {allProductData.map((product) => (
                <React.Fragment key={product.id}>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-60px me-5">
                          <span className="symbol-label bg-light">
                            <Image
                              src={product.photo}
                              className="rounded"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                            />
                          </span>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <span className="text-dark fw-bold mb-1 fs-6">
                            {product.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {product.price && typeof product.price === "number"
                          ? formatToRupiah(product.price)
                          : formatPrice(product.price as string)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {product.stock}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                        {product.sold}
                      </span>
                    </td>
                    <td>
                      <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                        <input
                          className="form-check-input h-30px w-60px"
                          type="checkbox"
                          checked={product.is_active}
                          onChange={() => handleToggleActive(product.id)}
                          id={`flexSwitch${product.id}`}
                        />
                      </div>
                    </td>
                    <td>
                      <Link
                        to="/marketplace/semuaproduk/edit-produk"
                        state={{ id: product.id }}
                        className="btn btn-sm btn-light me-2"
                      >
                        Edit
                      </Link>
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={`#kt_modal_delete_${product.id}`}
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                  <DeleteModalProduct
                    modalId={`kt_modal_delete_${product.id}`}
                    ids={[product.id]}
                    names={[product.name]}
                    onDelete={handleDeleteProduct}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const TransactionsTable: React.FC<{
  transactions: ReadonlyArray<Transaction>;
}> = ({ transactions }) => (
  <div className="card shadow-sm">
    <div className="card-header py-4 d-flex justify-content-between align-items-center">
      <h3 className="card-title fw-bold m-0">Transaksi Toko</h3>
    </div>
    <div className="card-body py-3">
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead>
            <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
              <th className="min-w-125px">Kode Transaksi</th>
              <th className="min-w-200px">Produk</th>
              <th className="min-w-125px">Pendapatan</th>
              <th className="min-w-125px">Status</th>
              <th className="min-w-125px">Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {transaction.code}
                  </span>
                </td>
                <td>
                  {transaction.products.map((product, idx) => (
                    <div
                      key={idx}
                      className="d-flex align-items-center mb-2 last-mb-0"
                    >
                      <div className="symbol symbol-60px me-3">
                        <span className="symbol-label bg-light">
                          <Image
                            src={product.photo}
                            className="rounded"
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "contain",
                            }}
                          />
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-dark fw-bold fs-6">
                          {product.name}
                        </span>
                        <span className="text-muted fw-semibold">
                          Qty: {product.qty}
                        </span>
                      </div>
                    </div>
                  ))}
                </td>
                <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {formatToRupiah(transaction.income)}
                  </span>
                </td>
                <td className="text-center">
                  <div
                    className={`badge badge-light-${
                      transaction.status === "success" ? "success" : "danger"
                    } fw-bold`}
                  >
                    {transaction.status === "success"
                      ? "Berhasil"
                      : "Dibatalkan"}
                  </div>
                </td>
                <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {formatDate(transaction.date)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const BalanceHistoryTable: React.FC<{
  histories: ReadonlyArray<BalanceHistory>;
}> = ({ histories }) => (
  <div className="card shadow-sm">
    <div className="card-header py-4 d-flex justify-content-between align-items-center">
      <h3 className="card-title fw-bold m-0">Histori Saldo Toko</h3>
    </div>
    <div className="card-body py-3">
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead>
            <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
              <th className="min-w-125px">Tanggal</th>
              <th className="min-w-125px">Tipe</th>
              {/* <th className="min-w-125px">Kode</th> */}
              <th className="min-w-125px">Nominal</th>
              <th className="min-w-200px">Keterangan</th>
            </tr>
          </thead>
          <tbody>
            {histories.map((history) => (
              <tr key={history.id}>
                <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {formatDate(history.date)}
                  </span>
                </td>
                <td className="text-center">
                  <div
                    className={`badge badge-light-${
                      history.type === "credit" ? "success" : "danger"
                    } fw-bold`}
                  >
                    {history.type === "credit" ? "Kredit" : "Debit"}
                  </div>
                </td>
                {/* <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {history.code}
                  </span>
                </td> */}
                <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {formatToRupiah(history.amount)}
                  </span>
                </td>
                <td className="text-center">
                  <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                    {history.remark}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

interface ShopHeaderProps {
  name: string;
  image: string;
  location: string;
  phone: string;
  onTabChange: (tabId: TabId) => void;
  activeTab: TabId;
  verify: boolean;
  loading: boolean;
}

const InfoItem: React.FC<{
  icon: string;
  text: string;
  href?: string;
}> = ({ icon, text, href }) => (
  <div className="d-flex align-items-center text-gray-600">
    <i className={`bi ${icon} me-2 fs-5`}></i>
    {href ? (
      <a
        href={href}
        className="text-gray-600 text-hover-primary text-decoration-none"
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    ) : (
      <span>{text}</span>
    )}
  </div>
);

const ShopHeader: React.FC<ShopHeaderProps> = ({
  name,
  image,
  location,
  phone,
  onTabChange,
  activeTab,
  verify,
  loading,
}) => {
  const contactInfoItems = useMemo(
    () => [
      { icon: "bi-telephone", text: phone },
      { icon: "bi-geo-alt", text: location },
    ],
    [location, phone]
  );

  // Memoized tabs

  return (
    <div className="">
      <div className="d-flex flex-column align-items-center mb-8">
        {/* Avatar */}
        <div className="position-relative mb-5">
          <div className="symbol symbol-100px">
            <Image
              src={image}
              className="h-100 w-100 rounded-circle border"
              style={{ objectFit: "cover" }}
              alt={name}
              preview={false}
              fallback="/media/avatars/blank.png"
            />
          </div>
        </div>

        {/* Name and Verification */}
        <div className="d-flex align-items-center gap-2 mb-4">
          <h2 className="text-dark fw-bolder fs-2 mb-0">{name}</h2>
          <div>
            {verify && (
              <img
                alt="Verified"
                src={toAbsoluteUrl("/media/logos/ic-verived.svg")}
                className="h-14px app-sidebar-logo-default ms-2"
              />
            )}
          </div>
        </div>

        {/* Contact Info */}
        <div className="d-flex flex-wrap justify-content-center gap-5">
          {contactInfoItems.map((item, index) => (
            <InfoItem
              key={index}
              icon={item.icon}
              text={item.text}
            />
          ))}
        </div>
      </div>

      {/* Navigation */}
      <ul className="nav nav-tabs nav-fill pt-4">
        {TABS.map((tab) => (
          <li className="nav-item" key={tab.id}>
            <button
              onClick={() => onTabChange(tab.id)}
              disabled={loading}
              className={`nav-link fw-bold ${
                activeTab === tab.id
                  ? "active text-primary border-bottom border-primary pb-4"
                  : ""
              }`}
              style={{ border: "none" }}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ShopManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabId>("edit");
  const [shopData, setShopData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState<{
    show: boolean;
    type: "success" | "error";
    message: string;
  }>({
    show: false,
    type: "success",
    message: "",
  });
  const { currentUser } = useAuth();
  const location = useLocation();
  const state = location.state as ShopData;

  // Handle alert messages
  const handleStatusChange = (success: boolean, message: string) => {
    setAlert({
      show: true,
      type: success ? "success" : "error",
      message,
    });

    // Auto hide alert after 3 seconds
    setTimeout(() => {
      setAlert((prev) => ({ ...prev, show: false }));
    }, 3000);
  };

  const fetchShopDetail = useCallback(async () => {
    if (!currentUser?.token || !state.id) return;

    try {
      const response = await apiProvider.GetShopDetail(
        currentUser.token,
        state.id
      );

      if (response.status === 200) {
        setShopData(response.data.data);
      }
    } catch (error) {
      handleStatusChange(false, "Error fetching shop details");
    } finally {
      setLoading(false);
    }
  }, [currentUser?.token, state.id]);

  useEffect(() => {
    fetchShopDetail();
  }, [fetchShopDetail]);

  const renderTabContent = useMemo(() => {
    if (loading) return <LoadingSpinner />;
    if (!shopData) return null;

    switch (activeTab) {
      case "edit":
        return <UpdateShop />;
      case "products":
        return (
          <ProductsTable
            products={shopData.products}
            onStatusChange={handleStatusChange}
          />
        );
      case "transactions":
        return <TransactionsTable transactions={shopData.transactions} />;
      case "balance":
        return <BalanceHistoryTable histories={shopData.balance_histories} />;
      default:
        return null;
    }
  }, [activeTab, loading, shopData]);

  return (
    <div className="container-fluid px-0">
      {alert.show && <AlertMessage type={alert.type} message={alert.message} />}

      {/* Shop Header */}
      <div className="card ">
        <div className="card-body pb-0 ">
          <ShopHeader
            name={state.name}
            image={state.photo}
            location={state.address_detail}
            phone={state.phone}
            onTabChange={setActiveTab}
            activeTab={activeTab}
            verify={state.is_verified}
            loading={loading}
          />
        </div>
      </div>

      {/* Content Area */}
      <div className="tab-content mt-5">
        <div className="tab-pane fade show active">{renderTabContent}</div>
      </div>

      <style>
        {`
          .nav-stretch {
            gap: 2rem;
          }

          .nav-line-tabs {
            border-bottom: none;
          }

          .nav-line-tabs .nav-item {
            margin: 0;
          }

          .nav-line-tabs .nav-link {
            border: 0;
            border-bottom: 2px solid transparent;
            padding: 0.75rem 0;
            transition: color 0.2s ease;
          }

          .nav-line-tabs .nav-link:hover:not(.active) {
            border-color: transparent;
            color: var(--bs-primary);
          }

          .nav-line-tabs .nav-link.active {
            background-color: transparent;
            border-bottom: 2px solid var(--bs-primary);
            color: var(--bs-primary);
          }

          .symbol.symbol-100px {
            height: 100px;
            width: 100px;
            position: relative;
          }

          .symbol-label {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            border-radius: 50%;
          }
        `}
      </style>
    </div>
  );
};

export default ShopManagement;
