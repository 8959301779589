import React, { useEffect, useState, useCallback, useMemo } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";
import { Pagination } from "../../global/Pagination";
import { Empty, Spin } from "antd";
import SearchInput from "../../global/SearchInput";
import RupiahFormat from "../../global/Formatrupiah";
import ReferModal from "./components/CheckPhoneTransaction";
import { AlertMessage } from "../../global/AlertMessage";

interface Meta {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

interface TransactionComponentProps {
  className: string;
}

interface Order {
  id: number;
  code: string;
  grand_total: number;
  status: string;
  date: string;
  is_referred: boolean;
}

interface AlertState {
  show: boolean;
  type: "success" | "error";
  message: string;
}

const TransactionComponent: React.FC<TransactionComponentProps> = ({
  className,
}) => {
  const { currentUser } = useAuth();
  const [transactionsData, setTransactionsData] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [zeroData, setZeroData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [metaData, setMetaData] = useState<Meta>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [isReferModalOpen, setIsReferModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Order | null>(
    null
  );
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    type: "success",
    message: "",
  });

  // Memoize startIndex calculation
  const startIndex = useMemo(
    () => (metaData.page - 1) * metaData.per_page,
    [metaData.page, metaData.per_page]
  );

  // Memoize status mappings
  const statusMappings = useMemo(
    () => ({
      badge: {
        pending: "badge-light-primary",
        nego: "badge-light-warning",
        paid: "badge-light-info",
        process: "badge-light-warning",
        sent: "badge-light-success",
        success: "badge-light-success",
        delivered: "badge-light-warning",
        default: "badge-light-danger",
      },
      text: {
        pending: "Pending",
        nego: "Negosiasi",
        paid: "Diproses",
        process: "Dikemas",
        sent: "Dikirim",
        success: "Selesai",
        delivered: "Diterima",
        default: "Dibatalkan",
      },
    }),
    []
  );

  const formatDate = useCallback((dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  }, []);

  const fetchTransactions = useCallback(async () => {
    if (!currentUser?.token) return;

    setLoading(true);
    try {
      const perPage =
        metaData.per_page === -1 ? metaData.total : metaData.per_page;
      const response = await API.GetTransaction(
        currentUser.token,
        metaData.page,
        searchQuery,
        perPage
      );

      if (response.status === 200 && response.data.data.length > 0) {
        setTransactionsData(response.data.data);
        setMetaData(response.data.meta);
        setZeroData("");
      } else {
        setTransactionsData([]);
        setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
        setZeroData(response.data.message);
      }
    } catch (error) {
      setZeroData("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [
    currentUser?.token,
    metaData.page,
    metaData.per_page,
    metaData.total,
    searchQuery,
  ]);

  // Single effect for initial data fetch
  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  // Alert management
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (alert.show) {
      timeoutId = setTimeout(() => {
        setAlert((prev) => ({ ...prev, show: false }));
      }, 4000);
    }
    return () => clearTimeout(timeoutId);
  }, [alert.show]);

  const showAlert = useCallback(
    (type: "success" | "error", message: string) => {
      setAlert({
        show: true,
        type,
        message,
      });
    },
    []
  );

  const handleReferralSuccess = useCallback(
    (successMessage: string) => {
      showAlert("success", successMessage);
      fetchTransactions();
    },
    [fetchTransactions, showAlert]
  );

  const handleModalActions = useCallback((transaction?: Order) => {
    setSelectedTransaction(transaction || null);
    setIsReferModalOpen(!!transaction);
  }, []);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 1 && pageNumber <= metaData.total_pages) {
        setMetaData((prev) => ({ ...prev, page: pageNumber }));
      }
    },
    [metaData.total_pages]
  );

  const handleRecordsPerPageChange = useCallback((recordsPerPage: number) => {
    setMetaData((prev) => ({
      ...prev,
      page: 1,
      per_page: recordsPerPage === 0 ? -1 : recordsPerPage,
    }));
  }, []);

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
    setMetaData((prev) => ({ ...prev, page: 1 }));
  }, []);

  const renderTableContent = useCallback(() => {
    if (loading) {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            <Spin size="default" />
          </td>
        </tr>
      );
    }

    if (!transactionsData.length) {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            {zeroData && (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                  <span className="text-muted fw-bold">{zeroData}</span>
                }
              />
            )}
          </td>
        </tr>
      );
    }

    return transactionsData.map((transaction, index) => (
      <tr key={transaction.id}>
        <td className="text-center">
          <span className="text-dark fw-bold mb-1 fs-6">
            {startIndex + index + 1}
          </span>
        </td>
        <td className="text-center">
          <span className="text-dark fw-bold d-block mb-1 fs-6">
            {transaction.code}
          </span>
        </td>
        <td className="text-center">
          <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
            {formatDate(transaction.date)}
          </span>
        </td>
        <td className="text-center">
          <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
            <span
              className={`badge ${
                statusMappings.badge[
                  transaction.status as keyof typeof statusMappings.badge
                ] || statusMappings.badge.default
              }`}
            >
              {statusMappings.text[
                transaction.status as keyof typeof statusMappings.text
              ] || statusMappings.text.default}
            </span>
          </span>
        </td>
        <td className="text-center">
          <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
            <RupiahFormat value={transaction.grand_total} />
          </span>
        </td>
        <td className="text-center">
          <div className="d-flex justify-content-center gap-2">
            <Link
              to={`/marketplace/transaksi/detail/${transaction.id}`}
              state={{ id: transaction.id }}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <KTSVG
                path="/media/icons/duotune/ecommerce/ecm008.svg"
                className="svg-icon-3"
              />
            </Link>
            {transaction.status === "success" && !transaction.is_referred && (
              <button
                onClick={() => handleModalActions(transaction)}
                className="btn btn-icon btn-bg-light btn-active-color-success btn-sm"
                title="Refer Transaksi"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-3"
                />
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  }, [
    loading,
    transactionsData,
    zeroData,
    startIndex,
    formatDate,
    statusMappings,
    handleModalActions,
  ]);

  return (
    <div className={`card ${className}`}>
      {alert.show && <AlertMessage type={alert.type} message={alert.message} />}

      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List Transaksi</h3>
        </div>
      </div>

      <div className="separator" />

      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center justify-content-end w-100">
          <SearchInput placeholder="Search" onSearch={handleSearch} />
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="min-w-60px rounded-start">No</th>
                <th className="min-w-125px">Kode Transaksi</th>
                <th className="min-w-125px">Tanggal</th>
                <th className="min-w-125px">Status</th>
                <th className="min-w-100x">Total</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>{renderTableContent()}</tbody>
          </table>

          <Pagination
            totalRecords={metaData.total}
            recordsPerPage={metaData.per_page}
            currentPage={metaData.page}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
          />
        </div>
      </div>

      <ReferModal
        isOpen={isReferModalOpen}
        onClose={() => handleModalActions()}
        onSuccess={handleReferralSuccess}
        transactionData={
          selectedTransaction
            ? {
                id: selectedTransaction.id,
                code: selectedTransaction.code,
              }
            : undefined
        }
      />
    </div>
  );
};

export { TransactionComponent };
