import { useEffect } from "react";
import { Navigate, Routes } from "react-router-dom";
import { useAuth } from "./core/Auth";

export function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    const handleLogout = async () => {
      await logout();
      document.location.reload();
    };
    handleLogout();
  }, [logout]);

  return null;
}
