import { useState, useEffect, useCallback } from "react";
import { ChatUser } from "../type";
import { useAuth } from "../../auth";
import apiProvider from "../../../../utils/apiProvider";

export const useChat = (role: string) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ChatUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<ChatUser | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [error, setError] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");
  const { currentUser } = useAuth();

  const fetchUsers = useCallback(async () => {
    if (!currentUser?.token || !role) return;

    setLoading(true);
    try {
      const response = await apiProvider.GetChat(currentUser.token, role);
      if (response.status === 200 && response.data.data.length > 0) {
        setUsers(response.data.data);
        setError("");
      } else {
        setError(response.data.message);
        setUsers([]);
      }
    } catch (err) {
      setError("Error loading chat data");
      setUsers([]);
    } finally {
      setLoading(false);
    }
  }, [currentUser?.token, role]);

  const filteredUsers = useCallback(() => {
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.message.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (selectedUserId !== null) {
      const user = users.find((u) => u.id === selectedUserId) || null;
      setSelectedUser(user);
    }
  }, [selectedUserId, users]);

  return {
    loading,
    users: filteredUsers(),
    selectedUser,
    selectedUserId,
    error,
    searchTerm,
    setSearchTerm,
    setSelectedUserId,
  };
};
