import React, { useState } from "react";
import { useAuth } from "../../../auth";
import apiProvider from "../../../../../utils/apiProvider";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";

interface ReferModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  transactionData?: {
    id: number;
    code: string;
  };
}

interface UserData {
  id: number;
  photo: string;
  name: string;
  phone: string;
  email: string;
}

const ReferModal: React.FC<ReferModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  transactionData,
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isChecking, setIsChecking] = useState(false);

  const validatePhoneNumber = (phone: string) => {
    const phoneRegex = /^(\+62|62|0)8[1-9][0-9]{6,9}$/;
    return phoneRegex.test(phone);
  };

  const normalizePhoneNumber = (phone: string) => {
    if (phone.startsWith("62")) {
      return "0" + phone.substring(2);
    }
    return phone;
  };

  const handlePhoneCheck = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setUserData(null);

    if (!validatePhoneNumber(phoneNumber)) {
      setError("Format nomor telepon tidak valid");
      return;
    }

    if (!transactionData?.id || !currentUser?.token) {
      setError("Data transaksi tidak lengkap");
      return;
    }

    setIsChecking(true);
    try {
      const formData = new FormData();
      formData.append("phone", normalizePhoneNumber(phoneNumber));

      const response = await apiProvider.CheckReferral(
        currentUser.token,
        formData
      );

      if (response.status === 200) {
        setUserData(response.data.data);
      } else {
        setError(
          response.data?.message || "Gagal melakukan pengecekan referral"
        );
      }
    } catch (err) {
      setError("Terjadi kesalahan saat memproses referral");
    } finally {
      setIsChecking(false);
    }
  };

  const handleSubmitReferral = async () => {
    if (!userData || !transactionData?.id || !currentUser?.token) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("phone", normalizePhoneNumber(userData.phone));

      const response = await apiProvider.ReferalTransaction(
        currentUser.token,
        transactionData.id,
        formData
      );

      if (response.status === 200) {
        onSuccess(response.data.message);
        handleClose();
      } else {
        setError(response.data?.message || "Gagal menambahkan referral");
      }
    } catch (err) {
      setError("Terjadi kesalahan saat menambahkan referral");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setPhoneNumber("");
    setError("");
    setUserData(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header>
        <h5 className="modal-title fw-semibold">Refer Transaksi</h5>
        <button
          type="button"
          className="btn-close shadow-none"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        {transactionData && (
          <div className="bg-light rounded p-3 mb-4">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <div className="bg-primary bg-opacity-10 rounded-circle p-3">
                  <KTSVG
                    path="/media/icons/duotune/ecommerce/ecm008.svg"
                    className="svg-icon-1"
                  />
                </div>
              </div>
              <div>
                <small className="text-muted d-block mb-1">
                  Kode Transaksi
                </small>
                <span className="fw-semibold">{transactionData.code}</span>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handlePhoneCheck}>
          <div className="mb-4">
            <label
              htmlFor="phoneNumber"
              className="form-label text-muted small mb-2"
            >
              Nomor Telepon
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg bg-light border-0 shadow-none"
                id="phoneNumber"
                placeholder="Masukkan nomor telepon"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                maxLength={15}
                required
              />
              <button
                className="btn btn-primary px-4"
                type="submit"
                disabled={isChecking}
              >
                {isChecking ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span>Mengecek...</span>
                  </>
                ) : (
                  "Cek"
                )}
              </button>
            </div>
            {error && (
              <div
                className="alert alert-danger d-flex align-items-center mt-3 py-2 mb-0"
                role="alert"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-exclamation-circle-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg>
                <small>{error}</small>
              </div>
            )}
          </div>
        </form>

        {userData && (
          <div className="card bg-light border-0 shadow-sm">
            <div className="card-body p-3">
              <div className="d-flex align-items-center mb-3">
                <div className="position-relative me-3">
                  <img
                    src={userData.photo}
                    alt={userData.name}
                    className="rounded-circle"
                    width="60"
                    height="60"
                    style={{ objectFit: "cover" }}
                  />
                  {/* <span
                    className="position-absolute bottom-0 end-0 bg-success rounded-circle border border-white"
                    style={{ width: "12px", height: "12px" }}
                  ></span> */}
                </div>
                <div className="overflow-hidden">
                  <h6 className="fw-semibold mb-1 text-truncate fs-4">
                    {userData.name}
                  </h6>
                  <div className="d-flex align-items-center text-muted small">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg>
                    <span className="text-truncate fs-6">{userData.phone}</span>
                  </div>
                  <div className="text-muted small d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg>
                    <span className="text-truncate fs-6">{userData.email}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light px-4"
          onClick={handleClose}
        >
          Batal
        </button>
        {userData && (
          <button
            type="button"
            className="btn btn-primary px-4 d-inline-flex align-items-center"
            onClick={handleSubmitReferral}
            disabled={loading}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Memproses...</span>
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-check2 me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                </svg>
                Konfirmasi Referral
              </>
            )}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReferModal;
