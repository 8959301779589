import React, { useEffect, useRef, useState } from "react";
import { Image } from "antd";
import { useChatMessages } from "../hooks/useChatMessages";
import { ChatMessage } from "../type";
import clsx from "clsx";

interface ChatInnerProps {
  isDrawer?: boolean;
  name: string;
  photo: string;
  messageId: number | null;
}

export const ChatInner: React.FC<ChatInnerProps> = ({
  isDrawer = false,
  name,
  photo,
  messageId,
}) => {
  const [messageInput, setMessageInput] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [showButtons, setShowButtons] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const MAX_FILE_SIZE_MB = 2048;

  const formatToRupiah = (price: number) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return formatter.format(price);
  };

  const {
    messages,
    loading,
    chatUserId,
    userHasScrolled,
    setUserHasScrolled,
    sendMessage,
  } = useChatMessages(messageId);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    setUserHasScrolled(scrollTop + clientHeight < scrollHeight - 10);

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setUserHasScrolled(false);
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size / (1024 * 1024) <= MAX_FILE_SIZE_MB) {
        setSelectedFile(file);
        setPreviewPhoto(URL.createObjectURL(file));
      }
    }
  };

  const handleDeleteImage = () => {
    setPreviewPhoto(null);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSendMessage = async () => {
    const trimmedMessage = messageInput.trim();
    if (!trimmedMessage && !selectedFile) return;

    const success = await sendMessage(
      trimmedMessage,
      selectedFile || undefined
    );

    if (success) {
      setMessageInput("");
      handleDeleteImage();
      setUserHasScrolled(false);
    } else {
      alert("Failed to send message. Please try again.");
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  // Auto scroll to bottom when messages change
  useEffect(() => {
    const container = containerRef.current;
    if (!container || !messages.length) return;

    container.scrollTop = container.scrollHeight;
  }, [messages]);

  const renderMessageContent = (message: ChatMessage) => (
    <div className="d-flex flex-column">
      {message.photo && (
        <div
          className="position-relative mb-4"
          style={{ width: "125px", height: "125px" }}
        >
          <Image
            className="w-100 h-100 object-fit-cover rounded"
            src={message.photo}
            alt="Uploaded photo"
            width={125}
            height={125}
            style={{ objectFit: "cover" }}
          />
        </div>
      )}

      {message.product && (
        <div className="card mb-3 shadow-sm border-light rounded">
          <div className="card-body p-2">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <Image
                  src={message.product.photo}
                  alt={message.product.name}
                  className="rounded object-fit-cover"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              <div>
                <h5 className="mb-1 text-dark">{message.product.name}</h5>
                {message.product.variant && (
                  <p className="text-muted mb-1">{message.product.variant}</p>
                )}
                <p className="card-text text-dark mb-1">
                  {formatToRupiah(message.product.price || 0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {message.transaction && (
        <div className="card mb-3 shadow-sm border-light rounded">
          <div className="card-body p-2">
            <div className="d-flex align-items-center">
              {message.transaction.products.length > 0 && (
                <div className="me-3">
                  {message.transaction.products.map((product) => (
                    <div
                      key={product.id}
                      className="d-flex align-items-center mb-2"
                    >
                      <Image
                        src={product.photo}
                        alt={`Product ${product.id}`}
                        className="rounded object-fit-cover"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div>
                <h6 className="card-subtitle mb-2 text-muted">
                  {message.transaction.code}
                </h6>
                <p className="card-text text-dark mb-1">
                  {formatToRupiah(message.transaction.grand_total)}
                </p>
                <p className="card-text text-muted mb-0">
                  {message.transaction.status}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div dangerouslySetInnerHTML={{ __html: message.message }} />
    </div>
  );

  return (
    <div className="d-flex flex-column h-100">
      {" "}
      {/* Wrapper to ensure full height */}
      <div className="card-header" id="kt_chat_messenger_header">
        <div className="card-title">
          <div className="symbol-group symbol-hover">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-45px symbol-circle">
                <img src={photo} alt={name} />
              </div>
              <div className="ms-5">
                <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                  {name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-body overflow-auto"
        style={{ height: "750px" }}
        ref={containerRef}
      >
        <div className="scroll-y me-n5 pe-5">
          {messages.map((message: ChatMessage) => (
            <div
              key={message.id}
              className={clsx("d-flex mb-10", {
                "justify-content-start": !message.is_you,
                "justify-content-end": message.is_you,
              })}
            >
              <div
                className={clsx("d-flex flex-column align-items p-5 rounded", {
                  "bg-light-info": !message.is_you,
                  "bg-light-primary": message.is_you,
                })}
              >
                {renderMessageContent(message)}
                <span
                  className={clsx("text-muted fs-7 mt-2", {
                    "text-end": message.is_you,
                  })}
                >
                  {new Date(message.date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="card-footer pt-4">
        {previewPhoto && (
          <div
            className="position-relative me-3"
            onMouseEnter={() => setShowButtons(true)}
            onMouseLeave={() => setShowButtons(false)}
          >
            <Image
              src={previewPhoto}
              alt="Preview"
              className="img-thumbnail"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                objectFit: "cover",
              }}
            />
            {showButtons && (
              <button
                className="btn btn-sm btn-icon btn-light-danger position-absolute top-0 end-0 m-2"
                onClick={handleDeleteImage}
              >
                <i className="bi bi-x"></i>
              </button>
            )}
          </div>
        )}

        <div className="d-flex align-items-center">
          <textarea
            className="form-control form-control-flush mb-3"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message"
            rows={1}
            style={{ resize: "none" }}
          />
        </div>

        <div className="d-flex flex-stack">
          <div className="d-flex align-items-center me-2">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <button
              className="btn btn-sm btn-icon btn-active-light-primary me-1"
              type="button"
              onClick={() => fileInputRef.current?.click()}
            >
              <i className="bi bi-paperclip fs-3"></i>
            </button>
          </div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleSendMessage}
            disabled={loading || (!messageInput.trim() && !selectedFile)}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
