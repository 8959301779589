import Pusher from "pusher-js";
import { ChatMessage, ChatMeta } from "../type";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../auth";
import apiProvider from "../../../../utils/apiProvider";

export const useChatMessages = (messageId: number | null) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [meta, setMeta] = useState<ChatMeta>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [chatUserId, setChatUserId] = useState<number | null>(null);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const { currentUser } = useAuth();

  const initializePusher = useCallback(() => {
    if (!messageId) return;

    const pusher = new Pusher("b1850c2dae770ae81718", {
      cluster: "ap1",
      enabledTransports: ["ws", "wss"],
    });

    const channelName = `conversation.${messageId}`;
    const channel = pusher.subscribe(channelName);

    channel.bind(channelName, (data: ChatMessage) => {
      setMessages((prevMessages) => {
        const isFromCurrentUser = data.user_id === chatUserId;
        const modifiedData = { ...data, is_you: isFromCurrentUser };

        const isDuplicate = prevMessages.some(
          (msg) =>
            msg.id === modifiedData.id ||
            (msg.message === modifiedData.message &&
              msg.user_id === modifiedData.user_id &&
              Math.abs(
                new Date(msg.date).getTime() -
                  new Date(modifiedData.date).getTime()
              ) < 1000)
        );

        if (isDuplicate) return prevMessages;
        return [...prevMessages, modifiedData];
      });

      if (!userHasScrolled) {
        // Trigger scroll to bottom
      }
    });

    return () => {
      channel.unbind_all();
      pusher.unsubscribe(channelName);
      pusher.disconnect();
    };
  }, [messageId, chatUserId, userHasScrolled]);

  const fetchMessages = useCallback(
    async (page = 1) => {
      if (!currentUser?.token || !messageId) return;

      setLoading(true);
      try {
        const response = await apiProvider.GetDetailChat(
          currentUser.token,
          messageId,
          page,
          999999
        );
        if (response.status === 200 && response.data.data.length > 0) {
          setMessages((prev) => {
            const newMessages = response.data.data.reverse();
            return [
              ...newMessages.filter(
                (newMsg: ChatMessage) =>
                  !prev.some((prevMsg) => prevMsg.id === newMsg.id)
              ),
              ...prev,
            ];
          });
          setMeta(response.data.meta);

          // Set chat user ID from first message that is yours
          const yourMessage = response.data.data.find(
            (msg: ChatMessage) => msg.is_you
          );
          if (yourMessage) {
            setChatUserId(yourMessage.user_id);
          }
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    },
    [currentUser?.token, messageId]
  );

  const sendMessage = useCallback(
    async (message: string, file?: File) => {
      if (!currentUser?.token || !messageId) return false;

      const formData = new FormData();
      formData.append("message", message);
      if (file) formData.append("photo", file);

      try {
        const response = await apiProvider.SendChat(
          formData,
          currentUser.token,
          messageId
        );
        return response.status === 200;
      } catch (error) {
        console.error("Error sending message:", error);
        return false;
      }
    },
    [currentUser?.token, messageId]
  );

  useEffect(() => {
    if (messageId) {
      setMessages([]);
      fetchMessages();
    }
  }, [messageId, fetchMessages]);

  useEffect(() => {
    const cleanup = initializePusher();
    return () => {
      if (cleanup) cleanup();
    };
  }, [initializePusher]);

  return {
    messages,
    meta,
    loading,
    chatUserId,
    userHasScrolled,
    setUserHasScrolled,
    fetchMessages,
    sendMessage,
  };
};
