import React from "react";
import { Empty } from "antd";
import { ChatUser } from "../type";
import { KTSVG } from "../../../../_metronic/helpers";
import { timeAgo } from "../../../../utils/formatters";

interface ChatUserListProps {
  users: ChatUser[];
  selectedUserId: number | null;
  loading: boolean;
  error: string;
  searchTerm: string;
  onSearchChange: (term: string) => void;
  onUserSelect: (id: number) => void;
}

export const ChatUserList: React.FC<ChatUserListProps> = ({
  users,
  selectedUserId,
  loading,
  error,
  searchTerm,
  onSearchChange,
  onUserSelect,
}) => {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-h-400px">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="card card-flush">
      <div className="card-header pt-7">
        <form className="w-100 position-relative" autoComplete="off">
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
          />
          <input
            type="text"
            className="form-control form-control-solid px-15"
            name="search"
            placeholder="Search by name or message"
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </form>
      </div>

      <div className="card-body pt-5">
        <div
          className="scroll-y me-n5 pe-5 h-200px h-lg-auto"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
          data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body"
          data-kt-scroll-offset="0px"
        >
          {users.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span className="text-muted fw-bold">{error}</span>}
            />
          ) : (
            users.map((user) => (
              <div
                key={user.id}
                className={`d-flex flex-stack py-4 p-4 rounded ${
                  selectedUserId === user.id ? "active bg-light" : ""
                }`}
                onClick={() => onUserSelect(user.id)}
                style={{ cursor: "pointer" }}
                onMouseEnter={(e) => e.currentTarget.classList.add("bg-light")}
                onMouseLeave={(e) => {
                  if (selectedUserId !== user.id) {
                    e.currentTarget.classList.remove("bg-light");
                  }
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-45px symbol-circle">
                    <img src={user.photo} alt={user.name} />
                  </div>

                  <div className="ms-5">
                    <span className="fs-5 fw-bolder text-gray-900 mb-2">
                      {user.name}
                    </span>
                    <div className="fw-bold text-gray-400">{user.message}</div>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-end ms-2">
                  <span className="text-muted fs-7 mb-1">
                    {timeAgo(user.last_online)}
                  </span>
                  {user.count > 0 && selectedUserId !== user.id && (
                    <span className="badge badge-sm badge-circle badge-light-success">
                      {user.count}
                    </span>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
