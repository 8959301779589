import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";

// Types
interface SubCategory {
  id: number;
  name: string;
  fee: number;
}

interface FormData {
  name: string;
  fee: string | number;
  icon: File | null;
  iconURL: string;
}

interface UpdateSubCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpdateSubCategory: (formData: FormData, id: number) => Promise<void>;
  id: number | null;
  category?: SubCategory | null;
}

const ALLOWED_FILE_TYPES = ".png, .jpg, .jpeg";
const BLANK_IMG = toAbsoluteUrl("/media/svg/avatars/blank.svg");

const compressImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;

      img.onload = async () => {
        const compressWithSize = async (
          targetSizeKB: number
        ): Promise<File> => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const targetSize = targetSizeKB * 1024;
          const scaleFactor = Math.sqrt(targetSize / file.size);

          let width = img.width * scaleFactor;
          let height = img.height * scaleFactor;

          // Ensure minimum dimensions
          const minDimension = 200;
          if (width < minDimension || height < minDimension) {
            const aspectRatio = width / height;
            if (width < height) {
              width = minDimension;
              height = minDimension / aspectRatio;
            } else {
              height = minDimension;
              width = minDimension * aspectRatio;
            }
          }

          width = Math.floor(width);
          height = Math.floor(height);

          canvas.width = width;
          canvas.height = height;

          if (ctx) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(img, 0, 0, width, height);
          }

          return new Promise((resolve, reject) => {
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const compressedFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: Date.now(),
                  });
                  resolve(compressedFile);
                } else {
                  reject(new Error("Canvas to Blob conversion failed"));
                }
              },
              file.type,
              1.0
            );
          });
        };

        try {
          let targetSize = 95; // Start with 95KB target
          let compressedFile = await compressWithSize(targetSize);

          // If still too large, gradually reduce target size
          while (compressedFile.size > 100 * 1024) {
            targetSize = targetSize * 0.9;
            compressedFile = await compressWithSize(targetSize);
          }

          resolve(compressedFile);
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const UpdateSubCategoryModal: React.FC<UpdateSubCategoryModalProps> = ({
  isOpen,
  onClose,
  handleUpdateSubCategory,
  id,
  category,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    fee: "",
    icon: null,
    iconURL: "",
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      name: category?.name || "",
      fee: category?.fee || "",
    }));
  }, [category]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (isOpen) {
      bodyClassList.add("modal-open");
    } else {
      bodyClassList.remove("modal-open");
    }
    return () => bodyClassList.remove("modal-open");
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (!files?.length) return;

    try {
      const file = files[0];
      let finalFile = file;

      // Compress if file is larger than 95KB
      if (file.size > 95 * 1024) {
        finalFile = await compressImage(file);
      }

      const fileURL = URL.createObjectURL(finalFile);
      setFormData((prev) => ({
        ...prev,
        icon: finalFile,
        iconURL: fileURL,
      }));
    } catch (error) {
      console.error("Error processing file:", error);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    await handleFileUpload(e.dataTransfer.files);
  };

  const handleClose = () => {
    setFormData({
      name: "",
      fee: "",
      icon: null,
      iconURL: "",
    });
    onClose();
  };

  const handleSave = async () => {
    if (id === null) return;

    await handleUpdateSubCategory(formData, id);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div
        className="modal fade show d-block"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h2 className="fw-bolder">Edit Sub Kategori</h2>
              <button
                type="button"
                className="btn btn-icon btn-sm btn-active-icon-primary"
                onClick={handleClose}
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </button>
            </div>

            {/* Body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form className="form" noValidate>
                {/* Icon Upload */}
                <div
                  className="fv-row mb-7"
                  id="drop-zone"
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <label className="d-block fw-bold fs-6 mb-2 required">
                    Icon
                  </label>
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                  >
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{
                        backgroundImage: `url('${
                          formData.iconURL || BLANK_IMG
                        }')`,
                        borderRadius: "100%",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    />
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change icon"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      <input
                        type="file"
                        name="icon"
                        accept={ALLOWED_FILE_TYPES}
                        onChange={(e) => handleFileUpload(e.target.files)}
                      />
                      <input type="hidden" name="icon_remove" />
                    </label>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      title="Cancel icon"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                  </div>
                  <div className="form-text">
                    Tipe file yang diizinkan: png, jpg, jpeg.
                  </div>
                </div>

                {/* Name Input */}
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Nama Kategori
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Masukkan nama kategori"
                    autoComplete="off"
                  />
                </div>

                {/* Fee Input */}
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Biaya Penjual
                  </label>
                  <input
                    type="number"
                    name="fee"
                    value={formData.fee}
                    onChange={handleInputChange}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Masukkan biaya penjual"
                    autoComplete="off"
                  />
                </div>
              </form>
            </div>

            {/* Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export { UpdateSubCategoryModal };
